import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  standalone: true,
  selector: 'social-login-btn',
  imports: [CommonModule],
  templateUrl: './social-login-btn.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host .color-blue {
        background-color: #3b5998;
        border-color: #3b5998;
      }
      :host .color-red {
        background-color: #dc4e41;
        border-color: #dc4e41;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLoginBtnAtom implements OnInit {
  @Input() startText = 'Đăng nhập bằng ';
  @Input() name = 'Facebook';
  @Input() mode = 'login';
  @Output() socialBtnClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
