<div [ngClass]="active ? 'bg-primary text-white' : 'bg-white text-primary'"
  class="sm: text-xs font-bold border border-primary rounded mr-2 sm:mr-4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100"
  (click)="submitEvent.emit()">
  <div class="p-1 text-xs flex justify-center items-center cursor-pointer">
    <fa-icon [icon]="icon" class="mr-2"></fa-icon>
    <div>{{value}}</div>
    <div *ngIf="reaction === 1" class="ml-1">({{count.countReactionLike}})</div>
    <div *ngIf="reaction === 2" class="ml-1">({{count.countReactionDislike}})</div>
    <div *ngIf="reaction === 3" class="ml-1">({{count.countReactionFunny}})</div>
  </div>
</div>