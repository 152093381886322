import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBookmark, faComment, faHeart, faUser, faHeartBroken, faCoins, faThumbsUp, faThumbsDown, faFaceLaughSquint } from '@fortawesome/free-solid-svg-icons';
import { CircleImageAtom } from '../circle-image/circle-image.atom.js';

@Component({
  standalone: true,
  selector: 'atom-notification-item',
  imports: [CommonModule, RouterModule, CircleImageAtom, FontAwesomeModule],
  templateUrl: './notification-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      ::ng-deep atom-notification-item atom-img-m {
        transform: translate(-50%, -50%) !important;
      }
    
      :host .info-noti {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemAtom implements OnInit {
  @Input() notification: any;
  @Input() icons = { faComment, faHeart, faHeartBroken, faCoins, faThumbsUp, faThumbsDown, faFaceLaughSquint };
  @Output() seeNoti = new EventEmitter();
  @Input() isReader = true;
  origin = window.location.origin;


  ngOnInit(): void {
    if (this.origin.indexOf('w.') !== -1) {
      this.origin = this.origin.replace('w.', '');
    }
  }
}
