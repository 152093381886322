import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CurrentUserFacade } from '@awread/core/users';
import { CopyClipboardDirective } from '../../directives/copy-clipboard/copy-clipboard.directive.js';

@Component({
  standalone: true,
  selector: 'atom-suggest-event',
  imports: [CommonModule, RouterModule, CopyClipboardDirective],
  templateUrl: './suggest-event.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestEventAtom implements OnInit {
  // TODO: day khong cast data form vao a?
  ui = {
    username: this.currentUserFacade.currentUserQuery.getValue().username,
  };
  @Input() showSummary = false;
  @Input() showNumber = false;
  @Input() noLink = false;
  @Input() invitedUsers: any[] = [];
  @Input() eventsResults: { money: number }[] = [];
  constructor(private currentUserFacade: CurrentUserFacade) {}

  ngOnInit(): void {}

  get readedUsers() {
    return this.invitedUsers.filter((u) => u.status === 1);
  }

  calculateFriendToInvite() {
    const readedNumber = this.readedUsers.length;
    if (readedNumber < 3) {
      return 3;
    } else if (3 <= readedNumber && readedNumber < 10) {
      return 10;
    } else {
      return Math.round(readedNumber / 10 + 1);
    }
  }

  countTotalMoney(eventsResults: { money: number }[]) {
    return eventsResults.reduce((acc, cur) => acc + +cur.money, 0);
  }
}
