import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'input-form-profile',
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './input-form-profile.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFormProfileAtom implements OnInit {
  @Input() class = 'px-2 md:px-4 py-1 md:py-2';
  @Input() profileForm: FormGroup = this.fb.group({});
  @Input() item: any;
  @Output() blurEvent = new EventEmitter();
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}
}
