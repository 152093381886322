import { PageInfo } from '@awread/kernel/tools';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Book } from '../../models/index.js';
export interface CategoryBooksState extends EntityState<Book>, ActiveState {
  pageInfo: PageInfo;
}

const initialState = {
  pageInfo: {
    endCursor: undefined,
    hasNextPage: true,
    hasPreviousPage: undefined,
    startCursor: undefined,
  },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'category-books', resettable: true, idKey: 'bookId' })
export class CategoryBooksStore extends EntityStore<CategoryBooksState> {
  // ui: EntityUIStore<CategoryBookUIState>;
  constructor() {
    super(initialState);
    // this.createUIStore().setInitialEntityState();
  }

  updatePage(page: { hasNextPage: boolean; totalCount: number; sizePage: number }) {
    this.update(page);
  }

  resetPageInfo() {
    this.update(initialState);
  }
}
