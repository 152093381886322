import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@awread/global/environments';

@Component({
  standalone: true,
  selector: 'atom-title-book',
  imports: [CommonModule, RouterModule],
  templateUrl: './title-book.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host a {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      @media only screen and (max-width: 640px) {
        a {
          line-height: 15px;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleBookAtom {
  @Input() titleBook: string | undefined;
  @Input() size!: string;
  @Input() path = ['/b', 'bookNid'];
  @Input() queryParams = {};
  @Input() pathMode: 'normal' | 'no-path' | 'absolute' = 'normal';
  @Input() replaceUrl = false;
  host = environment.production ? 'https://awread.vn' : 'http://localhost:2000';



}
