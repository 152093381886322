import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  status: BehaviorSubject<any> = new BehaviorSubject<any>(null);



  showToast(message: string, typeToast: any, options?: any) {
    this.status.next({ message: message, typeToast: typeToast, options: options ?? {} });
  }
}
