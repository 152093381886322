<div class="bg-red-200 mb-4">
  <p class="text-justify px-4 py-4" *ngIf="ui.username">
    <span *ngIf="showSummary">
      Hệ thống đã có {{ eventsResults.length }} bao lì xì được mở với tổng giá trị: {{ countTotalMoney(eventsResults) }}đ. <br />
    </span>
    <!-- <span *ngIf="showNumber"> Bạn đã mời {{ invitedUsers.length }}/{{ calculateFriendToInvite() }} bạn đọc truyện tại
      awread, <br></span> -->
    <span *ngIf="showNumber">
      Bạn đã mời {{ invitedUsers.length }} bạn, và {{ readedUsers.length }}/{{ calculateFriendToInvite() }} bạn đã đọc truyện tại awread, chi tiết về thông tin
      lượt mời <a [routerLink]="'/e/invite-friend'" class="text-primary">tại đây</a> <br
    /></span>
    chia sẻ đường dẫn sau để mời bạn bè đăng ký và nhận lì xì 10k từ Awread (Bấm vào link để sao chép)
    <button [copy-clipboard]="'https://awread.vn/?i=' + ui.username" class="text-primary">https://awread.vn/?i={{ ui.username }}</button>
    chi tiết event <a [routerLink]="'/e/invite-friend'" class="text-primary">tại đây</a>
  </p>
</div>
