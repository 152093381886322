<div *ngIf="price" [ngClass]="className">
  <div class="w-fit bg-red-500 rounded md:text-xs text-white md:px-1 py-0.5 p-0.5 leading-none" *ngIf="percent && isDiscount">-{{percent}}%</div>
  <div [ngClass]="percent && isDiscount ? 'line-through text-gray-700 md:' : 'font-bold text-[#D8280E]'" class="px-0.5 sm:px-1 flex">
    {{price | number: '1.0-0'}}
    <fa-icon class="ml-1" [icon]="faCoins"></fa-icon>
  </div>
  <div *ngIf="percent && isDiscount" class="font-bold flex">
    {{currentPrice | number: '1.0-0'}}
    <fa-icon class="ml-1 text-[#D8280E]" [icon]="faCoins"></fa-icon>
  </div>
</div>
<div *ngIf="!price" class="text-xs sm: text-primary ">
  <div class="font-bold ">Miễn phí</div>
</div>
<!-- <pre>{{endDate}}</pre> -->