import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-rd-news-box-title',
  imports: [],
  templateUrl: './rd-news-box-title.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host .bg-style-light {
        background-color: #b1dac6;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdNewsBoxTitleAtom implements OnInit {
  @Input() firstText = 'C';
  @Input() title = 'hia sẻ kinh nghiệm';

  constructor() {}

  ngOnInit(): void {}
}
