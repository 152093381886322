import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CurrentUserStore, CurrentUserState } from './current-user.store.js';

@Injectable({ providedIn: 'root' })
export class CurrentUserQuery extends Query<CurrentUserState> {
  constructor(protected override store: CurrentUserStore) {
    super(store);
  }

  getHaveNoti() {
    return this.getValue().haveNoti;
  }

  getUsername() {
    return this.getValue().username;
  }

  getTerms() {
    return this.getValue().terms;
  }

  getRole() {
    return this.getValue().role;
  }

  getUserId() {
    return this.getValue().userId;
  }

  getUserNid() {
    return this.getValue().userNid;
  }

  selectUserId() {
    return this.select((e) => e.userId);
  }

  getSocialConnect() {
    return this.getValue().socialConnect || {};
  }
}
