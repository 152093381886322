import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'auth-title',
  imports: [],
  templateUrl: './auth-title.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthTitleAtom implements OnInit {
  @Input() title = 'Title';
  constructor() {}

  ngOnInit(): void {}
}
