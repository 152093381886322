import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-process-bar',
  imports: [],
  templateUrl: './process-bar.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessBarAtom implements OnInit {
  @Input() percentLoading = '50%';
  constructor() {}

  ngOnInit(): void {}
}
