<div class="bg-style-light p-2 w-full">
  <div class="border-2 border-white py-2">
    <div class="relative">
      <img class="ml-2" src="/global-assets/images/label_important_24px.webp" alt="" />
      <div class="font-bold text-white absolute top-1 left-6">
        <span class="text-4xl">{{firstText}}</span>
        <span class="text-xl">{{title}}</span>
      </div>
    </div>
  </div>
</div>
