import { map, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import type { CurrentUser } from '../models/index.js';
import { CurrentUserQuery, CurrentUserStore } from '../states/current-user/index.js';
import { CurrentUserApi } from '../apis/current-user.api.js';
import { SnackbarService } from '@awread/global/packages';
import { of } from 'rxjs';
import { UniversalService } from '@awread/kernel/services';
import { DOCUMENT, Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CurrentUserGear {
  constructor(
    private currentUserApi: CurrentUserApi,
    private currentUserStore: CurrentUserStore,
    private snackbarService: SnackbarService,
    private universalService: UniversalService,
    private currentUserQuery: CurrentUserQuery,
    @Inject(DOCUMENT) private document: Document
  ) { }

  getBookUserReading(bookId: string) {
    return this.currentUserApi.getBookUserReading(bookId);
  }

  fetchUserData() {
    if (this.universalService.isBrowser()) {
      if (localStorage.getItem('accessToken')?.length) {
        if (localStorage.getItem('accessToken')?.includes('?accessToken')) {
          localStorage.clear();
          return of();
        } else {
          if (this.universalService.isBrowser()) {
            return this.currentUserApi.getCurrentUser().pipe(
              tap((result: any) => {
                if (result && result.userId) {
                  this.currentUserStore.update(result);
                } else {
                  console.warn('accessToken is not working...', result);
                  this.snackbarService.showWarning('fetchUserData: vui lòng đăng nhập lại');
                  localStorage.clear();
                  // debugger;
                  setTimeout(() => {
                    this.document.location.reload();
                  }, 3000);
                }
              })
            );
          } else {
            return of();
          }
        }
      } else {
        return of();
      }
    } else {
      return of();
    }
  }

  updateUser(user: CurrentUser) {
    if (user.updatedAt) {
      this.currentUserStore.update({ updatedAt: user.updatedAt });
    }
    return this.currentUserApi
      .updateUser(user)
      .pipe()
      .subscribe({
        next: (result) => {
          this.snackbarService.showSuccess('Cập nhật thông tin tài khoản thành công!');
          this.currentUserStore.updateCurrentUserAkita(user);
        },
        error: (error) => {
          const message: string = error.message;
          if (message.includes('duplicate')) {
            this.snackbarService.showError('Tài khoản đã tồn tại!');
          } else {
            this.snackbarService.showError(error.message);
          }
        },
      });
  }

  createPersonal(user: CurrentUser) {
    return this.currentUserApi.createPersonal(user).subscribe(() => {
      this.snackbarService.showSuccess('Cập nhật thông tin cá nhân thành công!');
      this.currentUserStore.updateCurrentUserAkita(user);
    });
  }

  updatePersonal(user: CurrentUser) {
    return this.currentUserApi.updatePersonal(user).subscribe({
      next: (result) => {
        this.snackbarService.showSuccess('Cập nhật thông tin cá nhân thành công!');
        this.currentUserStore.updateCurrentUserAkita(user);
      },
      error: (error) => {
        console.warn('error', error.message);
        if (error.message.includes('because no values you can update were found')) {
          this.createPersonal(user);
        }
      },
    });
  }

  async connectThisAccountToSocial(user: any) {
    if (user?.provider) {
      this.currentUserApi.linkSocial(user).subscribe((result: any) => {
        if (result) {
          this.snackbarService.showSuccess(`Thành công! Từ bây giờ bạn có thể đăng nhập bằng ${user?.provider}`);
        }
      });
    }
  }

  agreeBecomeWriter() {
    return this.currentUserApi.updateRole('writer').subscribe((role) => {
      console.log('result role', role);
      this.currentUserStore.update({ role });
      this.currentUserApi.refreshToken().subscribe((token) => {
        if (token === 'null' || !token) {
          localStorage.removeItem('accessToken');
        }
        {
          localStorage.setItem('accessToken', token);
        }
        setTimeout(() => {
          location.reload();
        }, 3000);
      });
    });
  }

  updateHaveNoti(user: any) {
    console.log(user);
    return this.currentUserApi.updateHaveNoti(user.haveNoti).pipe(tap(this.currentUserStore.updateCurrentUserAkita({ ...user, haveNoti: false })));
  }

  updateHaveDialogNoti(haveDialogNoti?: any) {
    return this.currentUserApi.updateHaveDialogNoti(haveDialogNoti);
  }
}
