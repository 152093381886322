import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'atom-select-chapter',
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './select-chapter.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectChapterAtom {
  @Input() selectControl = new FormControl();
  @Output() action = new EventEmitter();
  @Input() class = '';
  @Input() mode = '';
  @Input() item!: any;




}
