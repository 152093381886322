import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DialogRef } from '@ngneat/dialog';

@Component({
  standalone: true,
  selector: 'atom-example-dialog',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './example-dialog.atom.html',
  styles: [`
  :host {
      display: block;
      height: 100%;
    }
`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleDialogAtom {
  @Input() faIcon = faTimes;
  @Input() closeButton = 0;
  @Input() location = 'top-2 right-4';
  @Output() closePopup = new EventEmitter();
  constructor(public dialogRef: DialogRef) { }


}
