<div class="grid grid-cols-8 gap-x-0 md:gap-x-20 h-full w-full p-4 md:p-10">
  <div class="flex absolute top-2 right-10 h-9 items-center">
    <atom-round-btn [title]="'Huỷ'" [color]="'bg-gray-200 text-grey-400'" class="mr-2" (submitEvent)="submitEvent.emit()"> </atom-round-btn>
    <atom-round-btn [title]="'Lưu'" (submitEvent)="saveData()"></atom-round-btn>
  </div>
  <!-- <div class="w-full col-span-12 md:col-span-8 flex justify-center items-center relative overflow-hidden rounded-md mb-10 md:mb-0">
    <div class="w-auto h-full">
      <image-cropper [roundCropper]="roundCropper" [cropperMinWidth]="288" [maintainAspectRatio]="true" [containWithinAspectRatio]="false"
        [onlyScaleDown]="true" [resizeToWidth]="288*4" [imageChangedEvent]="imageChangedEvent" [aspectRatio]=" aspectRatio || 1 / 1" format="webp"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
      </image-cropper>
    </div> -->

  <div class="col-span-4 self-center">
    <image-cropper [roundCropper]="roundCropper" [cropperMinWidth]="288" [maintainAspectRatio]="true" [containWithinAspectRatio]="false" [onlyScaleDown]="true"
      [resizeToWidth]="288*4" [imageChangedEvent]="imageChangedEvent" [aspectRatio]=" aspectRatio || 1 / 1" format="webp" (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
    </image-cropper>
    <!-- left-1/2 top-1/3 -->
    <div class="absolute rounded border-white border-2 mx-auto px-2 py-1" style="transform: translateX(-50%); left: 17%; top: 50%">
      <div class="text-white text-xs md: z-50  text-center">
        <div class="flex">
          <fa-icon [icon]="icon" class="text-white mr-1"></fa-icon>
          Di chuyển ảnh để thay đổi
        </div>
        <div>kích thước bìa 3/4</div>
      </div>
    </div>
  </div>

  <img class="col-span-4 col-start-8 cover-ratio self-center" [ngClass]="roundCropper ? 'rounded-full' : ''" [src]="ImageCroppedEvent?.objectUrl" />
</div>
<!-- <div class="w-full col-span-12 md:col-span-4 overflow-hidden rounded-md flex justify-center items-center p-4 md:p-0"> -->
<!-- </div> -->
<!-- </div> -->

<!-- <pre> img {{ imageChangedEvent }} </pre> -->