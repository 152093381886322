import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CircleImageAtom } from '../circle-image/circle-image.atom.js';

@Component({
  standalone: true,
  selector: 'atom-comment-item-child',
  imports: [CircleImageAtom, CommonModule],
  templateUrl: './comment-item-child.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentItemChildAtom implements OnInit {
  @Input() comment: any;
  @Input() kind: 'parent' | 'child' = 'parent';
  @Input() countComments: any[] = [];
  @Input() user: any;

  @Output() clickBtn = new EventEmitter();
  @Output() focusInput = new EventEmitter();
  @Output() displayRepComment = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
