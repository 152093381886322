import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'option-link',
  imports: [CommonModule, RouterModule],
  templateUrl: './option-link.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionLinkAtom implements OnInit {
  @Input() suggestText = '';
  @Input() link: any;
  @Input() title = '';
  @Output() btnClicked = new EventEmitter();
  constructor() {}
  ngOnInit(): void {}
}
