import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
@Component({
  standalone: true,
  selector: 'input-form',
  imports: [ReactiveFormsModule, FontAwesomeModule],
  templateUrl: './input-form.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host input[type='text'],
      input[type='number'],
      input {
        font-size: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFormAtom implements OnInit {
  @Input() type = 'text';
  @Input() inputForm: any;
  @Input() placeholder = 'Email Address';
  @Input() inputControl: FormControl = new FormControl('');
  @Input() faIcon = faEnvelope;
  @Input() id = this.placeholder.replace(/\s/g, '-') + Math.random();
  @Output() enter = new EventEmitter();
  @Output() blurEvent = new EventEmitter();
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  focus() {
    this.elementRef.nativeElement.querySelector('input').focus();
  }
}
