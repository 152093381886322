import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DialogService } from '@ngneat/dialog';
@Component({
  standalone: true,
  selector: 'atom-link',
  imports: [CommonModule, RouterModule],
  templateUrl: './link.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkAtom {
  @Input() link: any = {
    title: 'name',
    href: 'href',
    requireLogin: false,
    newTab: false,
    dropdown: [],
    dialog: '',
  };
  @Output() action = new EventEmitter();
  @Output() closeMenu = new EventEmitter();
  @Input() color = 'text-primary';
  @Input() classes: any;

  constructor(private dialogService: DialogService) { }



}
