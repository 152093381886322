import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'atom-rd-library-tab-item',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './rd-library-tab-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdLibraryTabItemAtom {
  @Input() tab: any;
  @Input() activeTabId!: string;
  @Output() submitEvent = new EventEmitter();



}
