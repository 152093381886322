import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  selector: 'atom-btn-rectangle',
  imports: [FontAwesomeModule, CommonModule],
  templateUrl: './btn-rectangle.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnRectangleAtom implements OnInit {
  @Input() faIcon: any;
  @Input() btnColor = 'bg-primary';
  @Input() textColor = 'text-white';
  @Input() text = 'Nhắn tin';
  @Input() class = 'px-5 py-2 rounded-lg';
  @Output() btnClicked = new EventEmitter();
  @Input() disabled = false;
  constructor() {}

  ngOnInit(): void {}
}
