import { CurrentUserFacade } from '@awread/core/users';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BookDetailAndChapterApi {
  constructor(private apollo: Apollo, private currentUserFacade: CurrentUserFacade) { }

  getBookInfo(bookNid: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation getBookInfo($bookNid: String) {
            bookInfo(input: { bookNid: $bookNid }) {
              json
            }
          }
        `,
        variables: {
          bookNid,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => ({
          ...res?.['data']?.['bookInfo']?.['json'],
        }))
      );
  }

  getBookStatisticInfo(bookNid: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query getBookStatisticInfo($bookNid: String) {
            mvBookAllies(first: 1, condition: {bookNid: $bookNid}) {
              nodes {
                updatedAt
                totalComment
                totalSale
                totalView
                totalPositive
                totalReview 
                totalChapter
                totalDonate
              }
            }
          }
        `,
        variables: {
          bookNid,
        },
        // fetchPolicy: 'no-cache',
      })
      .pipe(
        map((res) => ({
          ...res?.['data']?.['mvBookAllies']?.['nodes']?.[0],
        }))
      );
  }
}
