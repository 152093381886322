import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CurrentUserFacade } from '@awread/core/users';
import { CopyClipboardDirective } from '../../directives/index.js';

@Component({
  selector: 'atom-copy-link',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, CopyClipboardDirective],
  templateUrl: './copy-link.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyLinkAtom implements OnInit {
  iconCopy = faCopy;
  @Input() title = '';
  @Input() link = 'your-link';
  constructor() {}

  ngOnInit(): void {}
}
