import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { SnackbarService } from '@awread/global/packages';

@Directive({
  selector: '[copy-clipboard]',
  standalone: true,
})
export class CopyClipboardDirective {
  constructor(private snackbarService: SnackbarService) {}
  @Input('copy-clipboard') payload = '';

  @Input() context = '';

  @Output() copied = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  public async onClick(event: MouseEvent) {
    event.preventDefault();
    if (!this.payload) return;

    // const listener = (e: ClipboardEvent) => {
    //   const clipboard = e.clipboardData || (window as any['clipboardData']);
    //   clipboard.setData('text', this.payload.toString());
    //   e.preventDefault();
    //   this.copied.emit(this.payload);
    // };

    // document.addEventListener('copy', listener, false);
    // document.execCommand('copy');
    // document.removeEventListener('copy', listener, false);
    try {
      await navigator.clipboard.writeText(this.payload);
      console.log('Page URL copied to clipboard', this.payload);
      this.snackbarService.showSuccess('Đã sao chép');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
}
