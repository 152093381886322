import { ChangeDetectionStrategy, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'atom-tab',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tab.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabAtom implements OnInit {
  @Input() tabs!: any;
  constructor() {}
  @Output() activeTabEvent = new EventEmitter();
  ngOnInit(): void {}

  toggleTab(i: number) {
    this.tabs.forEach((tab: any) => {
      if (tab.tabId === i) {
        tab.tabActive = true;
        this.activeTabEvent.emit(tab.tabId);
      } else {
        tab.tabActive = false;
      }
    });
  }
}
