<ng-container *ngIf="link">
  <ng-container *ngIf="link.newTab; then newTabTemplate; else thisTabTemplate"></ng-container>
</ng-container>

<ng-template #newTabTemplate>
  <a (click)="closeMenu.emit($event)" [href]="link.href" target="_blank" [ngClass]="classes" class="outline-none link dim cursor-pointer inline-block">
    {{ link.title }}
  </a>
</ng-template>

<ng-template #thisTabTemplate>
  <a (click)="closeMenu.emit($event)" [routerLink]="link.href" [queryParams]="link.isQueryParams ? link.queryParams : null" [ngClass]="classes"
    class="outline-none link dim cursor-pointer inline-block">
    {{ link.title }}
  </a>
</ng-template>