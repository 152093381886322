<section class="relative h-full ">
  <div class="flex justify-center flex-col h-full p-8">
    <p class="whitespace-pre-wrap text-center pb-4 text-xl font-bold rounded-t-md ">
      {{ data.question }}
    </p>
    <p *ngIf="data.additionData?.description" class="whitespace-pre-wrap text-center pb-4 px-8 md:text-lg font-bold rounded-t-md ">
      {{ data.additionData?.description }}
    </p>
    <div *ngIf="data.bookPrice as bookPrice">
      <div class="flex justify-center items-center pb-4">
        <div class="w-fit bg-red-500 rounded md:text-xs text-white md:px-1 py-0.5 p-0.5 leading-none" *ngIf="bookPrice.percent && bookPrice.isDiscount">
          -{{bookPrice.percent}}%
        </div>
        <div [ngClass]="bookPrice.percent && bookPrice.isDiscount ? 'line-through text-gray-700 md:' : 'font-bold'" class="px-0.5 sm:px-1 flex">
          {{bookPrice.price | number: '1.0-0'}}
          <fa-icon class="ml-1 text-[#D8280E]" [icon]="faCoins"></fa-icon>
        </div>
        <div *ngIf="bookPrice.percent && bookPrice.isDiscount" class="font-bold flex">
          {{bookPrice.currentPrice | number: '1.0-0'}}
          <fa-icon class="ml-1 text-[#D8280E]" [icon]="faCoins"></fa-icon>
        </div>
      </div>
      <div *ngIf="bookPrice.endDate" class="flex justify-center items-center pb-4">
        Khuyến mại kết thúc: {{ bookPrice.endDate | date: 'dd/MM/yyyy'}}
      </div>
      <div class="flex justify-center items-center pb-4">
        Số dư hiện tại: {{ bookPrice.currentBalance | number: '1.0-0'}} <fa-icon class="ml-1" [icon]="faCoins"></fa-icon>
      </div>
    </div>

    <div class="flex justify-center md:justify-end pt-4 bg-base-100 border-t px-8 rounded-b-md">
      <button (click)="yes()" class="btn btn-outline btn-sm mx-4">
        <div>{{ data.additionData?.yes || 'Có' }}</div>
      </button>

      <button (click)="no()" class="btn btn-outline btn-sm mx-4">
        <div>{{ data.additionData?.no || 'Không' }}</div>
      </button>
    </div>
  </div>
</section>

<!-- <h1> HELLO WORK</h1> -->