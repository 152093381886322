<atom-example-dialog class="h-full w-full">
  <section class="relative h-full py-4 px-10">
    <div class="flex justify-center items-center flex-col ">
      <div class="py-4 w-16 flex justify-center">
        <img src="{{src}}" alt="Donate Success" class="w-full" />
      </div>

      <p>
        Bạn đã donate thành công
        <span class="font-bold text-primary ">{{data.quantity}} {{data.name}} </span>
        trị giá {{ data.value }} coin
      </p>
      <p class=" flex justify-center py-2">Cảm ơn bạn, hãy tiếp tục ủng hộ cho tác giả nhé</p>
    </div>
  </section>
</atom-example-dialog>