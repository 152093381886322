import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'atom-current-coin',
  imports: [CommonModule],
  templateUrl: './current-coin.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentCoinAtom implements OnInit {
  @Input() currentCoin: any;
  @Input() srcCoin = '/global-assets/icons/Group-706_1.webp';
  constructor() {}

  ngOnInit(): void {}
}
