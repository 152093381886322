import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { UniversalService } from '@awread/kernel/services';
@Component({
  standalone: true,
  selector: 'scroll-snap',
  imports: [CommonModule],
  templateUrl: './scroll-snap.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      .slide::-webkit-scrollbar {
        display: none;
      }
      .slide {
        scroll-snap-type: x proximity;
      }
    `,
  ],
})
export class ScrollSnapComponent implements OnInit, AfterViewInit {
  @Input() arrayData!: any;
  @Input() typeBook!: any;
  @Input() scrollSnapConfig = {
    navigation: {
      usingNavigation: false,
      location: {
        locationArrowLeft: '',
        locationArrowRight: '',
      },
      style: '',
    },
    dot: {
      usingDot: false,
      type: '',
      location: '',
      style: {
        dotActive: '',
        dotNormal: '',
        shape: '',
      },
      text: {
        firstText: '',
        textDefault: '',
      },
    },
  };
  @ContentChild('slideItem') slideItem!: ElementRef;
  @ViewChild('slide', { read: ElementRef }) slide!: ElementRef;
  iconArrowLeft = faAngleLeft;
  iconArrowRight = faAngleRight;
  currentSlide = 0;
  constructor(private cd: ChangeDetectorRef, private universalService: UniversalService) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    if (this.universalService.isBrowser()) {
      if (this.typeBook) {
        this.scrollCurrentArea();
      }
    }
  }

  scrollCurrentArea() {
    setTimeout(() => {
      const index = (this.arrayData.findIndex((area: any) => area.routeName === this.typeBook) - 1) as any;
      const widthSlide = this.slideItem.nativeElement.clientWidth;
      this.slide.nativeElement.scrollBy({ top: 0, left: index * widthSlide, behavior: 'smooth' });
    }, 1000);
  }

  scrollHandler(e: any) {
    const atSnappingPoint = e.target.scrollLeft % e.target.offsetWidth === 0;
    const timeOut = atSnappingPoint ? 0 : 150;

    clearTimeout(e.target.scrollTimeout);

    e.target.scrollTimeout = setTimeout(() => {
      this.getActiveArea(e);
    }, timeOut);
  }

  getActiveArea(e: any) {
    this.arrayData.slice.call(e.target.children).forEach((slide: any, index: any) => {
      const check = Math.abs(slide?.getBoundingClientRect().left - e.target.getBoundingClientRect().left);
      if (slide?.getBoundingClientRect().left >= 0 && check < 100) {
        this.currentSlide = index;
        this.cd.detectChanges();
      }
    });
  }

  changeSlide(type: any) {
    const widthSlide = this.slideItem.nativeElement.clientWidth;
    this.slide.nativeElement.scrollBy({ top: 0, left: type === 'prev' ? -widthSlide : widthSlide, behavior: 'smooth' });
    if (type === 'prev' && this.currentSlide > 0) {
      this.currentSlide--;
      return;
    }
    if (type === 'next' && this.currentSlide < this.arrayData.length - 1) {
      this.currentSlide++;
      return;
    }
  }

  selectSlide(index: number) {
    const result = index - this.currentSlide;
    const widthArea = this.slideItem.nativeElement.clientWidth;
    this.slide.nativeElement.scrollBy({ top: 0, left: widthArea * result, behavior: 'smooth' });
    this.currentSlide = index;
  }
}
