import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { RoundBtnAtom } from '../round-btn/round-btn.atom.js';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'atom-popup-cover-image-align',
  imports: [CommonModule, FontAwesomeModule, ImageCropperModule, RoundBtnAtom],
  templateUrl: './popup-cover-image-align.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      ::ng-deep img.source-image {
        margin: 0 auto !important;
      }
      .cover-ratio {
        width: auto;
        /* aspect-ratio: 3/4; */
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCoverImageAlignAtom implements OnInit {
  @Input() sourceTarget = '/global-assets/images/bigImg.webp';
  @Output() submitEvent = new EventEmitter();
  icon = faArrowsAlt;
  @Input() aspectRatio = 1;
  @Input() roundCropper = true;
  @Input() imageChangedEvent: any;
  ImageCroppedEvent: ImageCroppedEvent | undefined;
  sanitizer = inject(DomSanitizer);
  fileChangeEvent(event: any): void {
    console.log('change event', event);
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    if (event) {
      this.ImageCroppedEvent = event;
    } else {
      console.log('event empty?', event);
    }
  }
  imageLoaded(image: LoadedImage) {
    console.log('image', image);
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  saveData() {
    console.log('save it', this.ImageCroppedEvent, this.ImageCroppedEvent?.blob);
    this.submitEvent.emit(this.ImageCroppedEvent?.blob);
  }

  ngOnInit(): void {}
}
