<!-- slide-item -->
<div (scroll)="scrollHandler($event)" #slide class="slide mx-auto flex overflow-x-auto w-full">
  <ng-content></ng-content>
</div>
<!-- navigation -->
<ng-container *ngIf="scrollSnapConfig.navigation.usingNavigation">
  <div [ngClass]="scrollSnapConfig.navigation.location.locationArrowLeft" class="absolute top-1/2 -translate-y-1/2">
    <div [ngClass]="scrollSnapConfig.navigation.style" (click)="changeSlide('prev')"
      class="cursor-pointer bg-white shadow rounded-full flex justify-center items-center">
      <span class="text-xl font-black">
        < </span>
    </div>
  </div>
  <div [ngClass]="scrollSnapConfig.navigation.location.locationArrowRight" class="absolute top-1/2 -translate-y-1/2">
    <div [ngClass]="scrollSnapConfig.navigation.style" (click)="changeSlide('next')"
      class="cursor-pointer bg-white shadow rounded-full flex justify-center items-center">
      <span class="text-xl font-black"> > </span>
    </div>
  </div>
</ng-container>
<!-- dot -->
<ng-container *ngIf="scrollSnapConfig.dot.usingDot">
  <div [ngClass]="scrollSnapConfig.dot.location" class="flex flex-wrap">
    <div (click)="selectSlide(i)" *ngFor="let item of arrayData; let i = index" class="m-1">
      <div [ngClass]="i === currentSlide ? scrollSnapConfig.dot.style.dotActive : scrollSnapConfig.dot.style.dotNormal"
        class="{{ scrollSnapConfig.dot.style.shape }}" [ngSwitch]="scrollSnapConfig.dot.type">
        <span *ngSwitchCase="'text'">{{ i === 0 ? scrollSnapConfig.dot.text.firstText : scrollSnapConfig.dot.text.textDefault + ' ' + i }}</span>
        <span *ngSwitchDefault></span>
      </div>
    </div>
  </div>
</ng-container>