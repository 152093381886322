<div class="shadow mb-4">
  <div class="flex md:flex-row flex-col md:items-center py-4">
    <h2 *ngIf="title && title.length" class="uppercase text-lg md:text-2xl font-bold md:pb-0 pb-2 px-4 mb-2 md:border-0 border-b border-gray-200">
      {{ title }}
    </h2>
    <button [copy-clipboard]="link" [ngClass]="{'w-10/12': title && title.length}" class="form-control my-2 md:my-0 px-4">
      <div class="flex">
        <input disabled="true" [value]="link || 'Awread'" type="text" placeholder="{{link || 'Awread'}}"
          class="font-bold w-full h-10 cp input input-bordered focus:outline-none" />
        <button title="copy"
          class="h-10 min-h-0 btn !rounded-none !rounded-r-lg -ml-2 btn-square bg-primary border-primary hover:border-primary hover:bg-primary">
          <fa-icon class="text-lg text-white" [icon]="iconCopy"></fa-icon>
        </button>
      </div>
    </button>
  </div>
</div>