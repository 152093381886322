<a *ngIf="book && book.bookId?.length > 10" class="relative" [routerLink]="[startPath, book.bookNid]" [queryParams]="queryParams" [replaceUrl]="replaceUrl">
  <!-- <div class="absolute scale-75 text-center  bottom-0 z-10 w-full">
    <div class="ribbon before:border-accent after:border-accent bg-accent text-white">
      <strong class="ribbon-content">Giải nhất</strong>
    </div>
    </div> -->


  <div class="focus:outline-none overflow-hidden">
    <!-- <div *ngIf="book.achivementName" [ngClass]="bigImage ? 'scale-75 md:scale-90 -left-4 -top-8 md:-top-12' : 'scale-50 md:scale-50 -left-6 -top-12 md:-top-16'"
      class="absolute mt-3 mr-6 w-16 text-xs md:mt-7 md:mr-8 md:w-14 text-center">
      <ng-container *ngTemplateOutlet="medalTemplate; context: { achivement: book.achivementName }"></ng-container>
    </div> -->
    <!-- <div *ngIf="book.status === 'completed' && showRibbon"
      class="origin-top float-right mt-3 mr-6 w-16 text-xs scale-75 md:scale-110 translate-x-1/2 md:translate-x-10 -translate-y-1/2 md:-translate-y-7 rotate-45 md:mt-7 md:mr-8 md:w-14 text-center end-ribbon">
      <div class="text-white -translate-x-1">Hết</div>
    </div> -->
    <button *ngIf="book.status === 'completed'"
      class="btn btn-xs btn-primary bg-primary/50 transform rotate-45 scale-75 md:scale-75 lg:scale-75 xl:scale-75 absolute text-xs -right-0.5 top-0.5  md:top-2 md:w-12">
      hết </button>
    <img *ngIf="book && book.bookId?.length > 10 && book.cover?.length; else defaultImgTemplate" width="288" height="384" [priority]="priority"
      [ngSrc]="book | coverSize: size" [ngClass]="rounded" [alt]="book.title" class="object-cover w-full cursor-pointer" />
    <ng-template #defaultImgTemplate>
      <img width="740" height="1000" [priority]="priority" [ngSrc]="'/global-assets/images/default-cover.webp'" [ngClass]="rounded" [alt]="book.title"
        class="object-cover w-full cursor-pointer" />
    </ng-template>
  </div>
</a>

<ng-template #medalTemplate let-achivement="achivement">
  <div class="quiz-medal">
    <!-- <pre> achivement {{ achivement }} {{ achivement === '3' }} </pre> -->
    <div [ngClass]="{
      'quiz-medal__circle--gold' : achivement === '1',
      'quiz-medal__circle--bronze' : achivement === '2',
      'quiz-medal__circle--silver' : achivement === '3'
    }" class="quiz-medal__circle">
      <span *ngIf="achivement === '1' || achivement === '2' || achivement === '3'">
        {{ achivement }}
      </span>
    </div>
    <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
    <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
  </div>
</ng-template>