import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck, faCoins } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'atom-popup-event-item',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './popup-event-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupEventItemAtom implements OnInit {
  @Input() icon: any;
  @Input() ui: any;
  @Input() dailyCheckin: any;
  icons = { faCheck, faCoins };
  constructor() {}

  ngOnInit(): void {}
}
