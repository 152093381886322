import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'atom-btn-input-file',
  imports: [ReactiveFormsModule],
  templateUrl: './btn-input-file.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnInputFileAtom implements OnInit {
  @Input() controlName = new FormControl('');
  @Input() title = 'Chọn hình ảnh từ thiết bị của bạn.';
  @Output() eventChooseImage = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
