import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { ImgMAtom } from '../img-m/img-m.atom.js';

@Component({
  standalone: true,
  selector: 'atom-book-featured-item',
  imports: [CommonModule, RouterModule, ImgMAtom,],
  templateUrl: './book-featured-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }

      :host .bg {
        background: linear-gradient(90deg, #d66767 0%, rgba(241, 168, 203, 0.96875) 62.7%, rgba(222, 127, 237, 0.96875) 95.54%);
      }

      :host #title {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2em;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      @media only screen and (max-width: 640px) {
        #title {
          line-height: 15px;
        }
      }

      :host p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      @media only screen and (max-width: 1025px) {
        :host p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 20px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }

      :host .shadoww {
        box-shadow: 0px 5px 5px rgba(167, 54, 156, 0.87);
      }

      :host .text-color {
        color: #b349a3;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookFeaturedItemAtom {
  faEye = faEye;
  @Input() book!: any;
  @Input() display!: any;



}
