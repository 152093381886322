<div *ngIf="mode === 'parent'" class="my-0 md:my-4  w-full relative textarea">
  <textarea #input (keydown.control.enter)="actionComment.emit({type: 'create-comment'})" id="review-item" rows="3" placeholder="{{placeholder}}"
    [formControl]="inputControl" name="comment"
    class="leading-5 block border-box hover-black lg:min-vh-8 w-full border border-solid border-gray-400 bg-gray-primary px-1 py-2 rounded-lg resize-none outline-none "></textarea>
  <!-- <div class="col-span-2 sm:col-span-1">
    <atom-circle-image [user]="user"></atom-circle-image>
  </div>
  <div class="pl-2 sm:pl-4 col-span-6 sm:col-span-7 flex justify-end items-center">
  </div> -->
  <ng-container *ngIf="isComment;else review">
    <div [ngClass]="inputControl.value && inputControl.value.length >= 10 ? 'bottom-9' : 'bottom-4'" class="tooltip absolute hidden z-50 left-32">
      <div class="font-bold hidden md:block">Ctrl + Enter</div>
    </div>
    <div [ngClass]="inputControl.value && inputControl.value.length >= 10 ? 'bottom-9' : 'bottom-4'" class="tooltip absolute hidden z-50 right-8">
      <div class="font-bold hidden md:block">{{inputControl.value ? inputControl.value.length : 0}} ký tự</div>
    </div>
    <ng-container *ngIf="inputControl.value && inputControl.value.length >= 10">
      <div class="italic text-primary  col-span-12 text-right">Tốt lắm, tác giả sẽ rất vui khi đọc bình luận của bạn</div>
    </ng-container>
  </ng-container>
  <ng-template #review>
    <div [ngClass]="inputControl.value && inputControl.value.length >= 50 ? 'bottom-9' : 'bottom-4'" class="tooltip absolute hidden z-50 right-8">
      <div class="font-bold hidden md:block">{{inputControl.value ? inputControl.value.length : 0}} ký tự</div>
    </div>
    <ng-container *ngIf="inputControl.value && inputControl.value.length >= 50">
      <div class="italic text-primary  col-span-12 text-right">Tốt lắm, tác giả sẽ rất vui khi đọc đánh giá của bạn</div>
    </ng-container>
  </ng-template>
</div>

<div *ngIf="mode === 'child'" class="my-2 grid grid-cols-10 leading-normal pl-1 pr-4 relative textarea">
  <div class="col-span-2 sm:col-span-1">
    <atom-circle-image [user]="user"></atom-circle-image>
  </div>
  <div class="pl-3 col-span-8 sm:col-span-9 flex justify-end items-center">
    <textarea (keydown.control.enter)="actionComment.emit({type: type, comment: comment})" id="your-review" rows="2" placeholder="{{placeholder}}"
      [formControl]="inputControl" name="comment"
      class="leading-5 block border-box hover-black  lg:min-vh-8 w-full border border-solid border-gray-400 bg-gray-primary px-3 sm:py-2 py-1.5 rounded-lg resize-none outline-none"
      aria-describeblocky="comment-desc"></textarea>
  </div>
  <div class="tooltip absolute hidden z-50 bottom-3 right-6">
    <div class="font-bold hidden md:block">Ctrl + Enter</div>
  </div>
</div>