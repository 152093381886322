import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ScrollSnapComponent } from '@awread/global/packages';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  selector: 'atom-slider',
  imports: [CommonModule, RouterModule, FontAwesomeModule, ScrollSnapComponent, NgOptimizedImage],
  templateUrl: './slider.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      .slide-item {
        scroll-snap-align: center;
      }
      .w-img {
        width: 35vw;
      }
      .dot-active {
        background-color: #007aff;
      }
      @media screen and (max-width: 1320px) {
        .w-img {
          width: 40vw;
        }
      }
      @media screen and (max-width: 767px) {
        .w-img {
          width: 80vw;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderAtom implements OnInit {
  @Input() priority = true;
  @Input() data: any;
  @Input() scrollSnapConfig = {
    navigation: {
      usingNavigation: true,
      location: {
        locationArrowLeft: 'left-4 md:left-8',
        locationArrowRight: 'right-4 md:right-8',
      },
      style: 'w-6 h-6 md:w-8 md:h-8',
    },
    dot: {
      usingDot: true,
      type: 'normal',
      location: 'absolute bottom-5 md:bottom-8 left-1/2 -translate-x-1/2',
      style: {
        dotActive: 'bg-blue-500',
        dotNormal: 'bg-gray-300',
        shape: 'w-7 md:w-9 h-1.5 md:h-2 md:rounded-sm cursor-pointer',
      },
      text: {
        firstText: '',
        textDefault: '',
      },
    },
  };

  constructor() {}

  ngOnInit(): void {}
}
