import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
@Injectable({ providedIn: 'root' })
export class BooksApi {
  constructor(private apollo: Apollo) { }

  quickSearch(searchTerm: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation quickSearch($searchTerm: String) {
            quickSearch(input: { searchTerm: $searchTerm }) {
              results {
                authors
                books
              }
            }
          }
        `,
        variables: { searchTerm },
      })
      .pipe(map((res) => res?.['data']?.['quickSearch']?.['results'][0]));
  }

  buyBookNow(bookNid: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation buyBookNow($bookNid: String) {
            buyBookNow(input: { bookNid: $bookNid }) {
              json
            }
          }
        `,
        variables: { bookNid },
      })
      .pipe(map((result: any) => result?.['data']?.['buyBookNow']?.['json']));
  }

  checkPrice(bookId: string, userId: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query checkPrice($bookId: UUID!, $userId: UUID!) {
            booksPrice(bookId: $bookId, userId: $userId) {
              price {
                price
              }
              discount {
                percent
                endDate
              }
            }
          }

        `,
        variables: { bookId, userId },
      })
      .pipe(map((result: any) => result?.['data']?.['booksPrice']));
  }

  donateBookNow(bookNid: string, donateData: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation donateBookNow($bookNid: String, $donateData: JSON) {
            donateBookNow(input: {bookNid: $bookNid, donateData: $donateData}) {
              json
            }
          }
        `,
        variables: { bookNid, donateData },
      })
      .pipe(map((res) => res?.['data']?.['donateBookNow']?.['json']));
  }

  createBookShare(bookId: string, userId: string, method: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
         mutation createBookShare($bookShare: BookShareInput!) {
            createBookShare(input: {bookShare: $bookShare}) {
              bookShare {
                bookId
                method
                userId
                timestamp
              }
            }
          }

        `,
        variables: { bookShare: { bookId, userId, method } },
      })
      .pipe(map((res) => res?.['data']?.['createBookShare']?.['json']));
  }


  readBook(bookNid: string, chapterNid?: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation readBook($bookNid: String, $chapterNid: String) {
            readBook(input: { bookNid: $bookNid, , chapterNid: $chapterNid }) {
              json
            }
          }
        `,
        variables: {
          bookNid,
          chapterNid
        },
        fetchPolicy: 'network-only'
      })
      .pipe(
        map((res) => ({
          ...res?.['data']?.['readBook']?.['json'],
        }))
      );
  }

}
