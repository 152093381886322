import { Pipe, PipeTransform } from '@angular/core';
import { s3CloudUrl } from '@awread/kernel/tools';
@Pipe({
  name: 'coverSize',
  standalone: true,
})
export class CoverSizePipe implements PipeTransform {

  //LEARN: Pipe tool
  transform(book: any, ...args: any[]): any {
    const size = args[0] ? args[0] : (globalThis as any)['isMobile'] ? 'l' : 'xl';
    // console.log('size', size, book, book && book.bookId?.length > 10 && book.cover?.length);
    if (book && book.bookId?.length > 10 && book.cover?.length) {
      return `${s3CloudUrl}/books/${size ?? 'origin'}/${book?.bookId}.webp?updatedAt=${book?.cover}`;
    }
    return '/global-assets/images/default-cover.webp';
  }
}
