import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { aAwread } from '../../../icons/awread.js';
@Component({
  standalone: true,
  selector: 'atom-logo-nolink',
  imports: [FontAwesomeModule],
  templateUrl: './logo-nolink.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoNolinkAtom implements OnInit {
  @Input() textSize = 'text-6xl';
  @Input() textColor = 'text-primary';
  iconAwread = aAwread;
  constructor() {}

  ngOnInit(): void {}
}
