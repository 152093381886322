<div *ngIf="inputControl.value && searchResult" class="shadow-accent shadow-sm w-full bg-base-200 rounded-xl px:1 py:2 md:px-12 md:py-4">
  <ng-container *ngIf="!inputControl.value.includes('author:')">
    <div *ngFor="let user of searchResult.authors" class="grid grid-cols-12 px-4 py-2 shadow cursor-pointer">
      <div class="col-span-3 sm:col-span-2 md:col-span-1 flex items-center">
        <div (click)="toggleDisplay.emit()" [routerLink]="['/a', user.username]" class="relative overflow-hidden rounded-full w-20 h-20">
          <atom-circle-image [user]="user" class="col-span-2 sm:col-span-1"> </atom-circle-image>
        </div>
      </div>
      <div class="col-span-9 sm:col-span-10 md:col-span-11 pl-5 font-medium flex flex-col justify-center">
        <div (click)="toggleDisplay.emit()" [routerLink]="['/a', user.username]" class="mb-2 info-noti">
          <span class="mr-1 " [innerHtml]="user.name_result"></span>
        </div>
        <div class="flex items-center justify-between">
          <span (click)="toggleDisplay.emit()" [routerLink]="['/a', user.username]" class="font-light "> {{user.username}} </span>
          <button (click)="inputControl.patchValue('author:' + user.username + ' '); focusInput.emit()"
            class="cursor-pointer outline-none py-3 w-max  text-primary italic font-bold px-6 md:px-8">
            tìm truyện
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="ml-4 my-2 text-gray-400">{{ inputControl.value.includes('author:') ? 'Đang tìm truyện của tác giả' : 'Tìm kiếm phổ biến' }}</div>

  <ng-container *ngFor="let book of searchResult.books; trackBy: tracker">
    <div class="flex transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 py-1">
      <img class="h-5 mr-4" src="/global-assets/images/ic_class_24px.webp" alt="" />
      <div class=" ">
        <a (click)="toggleDisplay.emit()" [routerLink]="['/b', book.bookNid]" class="outline-none cursor-pointer">
          <span [innerHTML]="book.title_result"></span>
        </a>
      </div>
    </div>
  </ng-container>
  <!-- <div class="text-gray-300 text-xs">Nhấn Enter để hiển thị tất cả kết quả tìm kiếm</div> -->
</div>