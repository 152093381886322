import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject, ChangeDetectorRef, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { DialogRef } from '@ngneat/dialog';
@Component({
  standalone: true,
  selector: 'app-confirm',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './confirm.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ConfirmComponent implements OnInit {
  dialogRef = inject(DialogRef<any>);
  cd = inject(ChangeDetectorRef);
  data = this.dialogRef.data as {
    question: string,
    additionData?: { description?: string, yes?: string, no?: string },
    bookPrice?: {
      endDate: any, price: number, percent: number,
      isDiscount: boolean, currentPrice: number, currentBalance: number
    }
  };
  faCoins = faCoins
  ngOnInit() {
    console.log('confirm component data', this.data);
    // mat dialog not open full and need workaround to trigger
    setTimeout(() => {
      this.cd.detectChanges();
    }, 100);
  }

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close();
  }
}
