import { SearchBooksStore } from './../states/search-books/search-books.store.js';
import { Injectable } from '@angular/core';
import { combineLatest, map, tap } from 'rxjs';
import { BookDetailAndChapterApi, BooksApi } from '../apis/index.js';
import { CategoryBooksStore } from '../states/category-books/index.js';

@Injectable({ providedIn: 'root' })
export class BooksGear {
  constructor(
    private categoryBooksStore: CategoryBooksStore,
    private searchBooksStore: SearchBooksStore,
    private bookDetailAndChapterApi: BookDetailAndChapterApi,
    private booksApi: BooksApi
  ) { }


  donateBookNow(bookNid: string, donateData: any) {
    return this.booksApi.donateBookNow(bookNid, donateData);
  }

  buyBookNow(bookNid: string) {
    return this.booksApi.buyBookNow(bookNid);
  }

  checkPrice(bookId: string, userId: string) {
    return this.booksApi.checkPrice(bookId, userId);
  }


  getBookStatisticInfo(bookNid: string) {
    return this.bookDetailAndChapterApi.getBookStatisticInfo(bookNid);
  }

  getBookInfo(bookNid: string) {
    return this.bookDetailAndChapterApi.getBookInfo(bookNid);
  }

  quickSearch(term: string) {
    this.searchBooksStore.setLoading(true);
    return this.booksApi.quickSearch(term).pipe(
      tap(() => this.searchBooksStore.setLoading(false))
    );
  }

  createBookShare(bookId: string, userId: string, method: string) {
    return this.booksApi.createBookShare(bookId, userId, method);
  }

  readBook(bookNid: string, chapterNid?: string) {
    return this.booksApi.readBook(bookNid, chapterNid);
  }

}
