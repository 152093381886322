import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { aAwread } from '../../../icons/awread.js';
@Component({
  standalone: true,
  selector: 'logo',
  imports: [CommonModule, RouterModule, FontAwesomeModule],
  templateUrl: './logo.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoAtom implements OnInit {
  @Input() logoWhite = false;
  @Input() isLogged = false;
  iconAwread = aAwread;
  constructor() {}

  ngOnInit(): void {}
}
