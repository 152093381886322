<div [ngClass]="tab.tabId === activeTabId ? 'md:border-primary' : 'border-transparent'"
  class="flex md:flex-row flex-col justify-center items-center pb-2 cursor-pointer md:border-b-4 " (click)="submitEvent.emit(tab)">
  <ng-container *ngIf="tab.icon; else imageTemplate">
    <fa-icon class="md:text-2xl text-3xl" [ngClass]="tab.tabId === activeTabId ? 'text-primary ' : ''" [icon]="tab.icon"></fa-icon>
  </ng-container>
  <div [ngClass]="tab.tabId === activeTabId ? 'text-primary ' : ''" class="md: text-xs text-center md:mx-2 my-2">{{tab.title}}</div>
</div>

<ng-template #imageTemplate>
  <div [ngClass]="tab.tabId === activeTabId ? 'border-primary' : 'border-gray-200'" class="border rounded-md p-2 w-12 h-12 flex items-center justify-center">
    <img [src]="tab.image" [width]="tab.width ?? 64" [height]="tab.height ?? 64" class="w-full" alt="Icon" />
  </div>
</ng-template>