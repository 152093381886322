<div class="form-field form-field--icon form-field--email mb-5">
  <div class="w-full relative  ">
    <input (blur)="blurEvent.emit($event)" (keyup.enter)="enter.emit(inputControl.value)" [id]="id" [type]="type" [formControl]="inputControl"
      [placeholder]="placeholder"
      class="appearance-none border border-primary md:text-xs lg: xl: rounded-full w-full py-3 sm:py-2 pl-12    leading-tight focus:outline-none shadow_input "
      autocapitalize="off" autocomplete="off" />
    <span class="absolute left-0 top-0 bottom-0 flex items-center ml-4 text-slate-800  ">
      <fa-icon [icon]="faIcon"></fa-icon>
    </span>
  </div>
</div>