import { Injectable, inject } from '@angular/core';
import { BooksGear } from '../gears/books.gear.js';
import { Book, BooksQuery, BooksStore } from '../states/books/index.js';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BooksFacade {
  booksQuery = inject(BooksQuery);
  booksGear = inject(BooksGear);
  books$ = this.booksQuery.selectAll();
  isLoading$ = this.booksQuery.selectLoading();

  getBookStatisticInfo(bookNid: string) {
    return this.booksGear.getBookStatisticInfo(bookNid);
  }

  getBookInfo(bookNid: string) {
    return this.booksGear.getBookInfo(bookNid);
  }


  quickSearch(term: string) {
    return this.booksGear.quickSearch(term);
  }


  donateBookNow(bookNid: string, donateData: any) {
    return this.booksGear.donateBookNow(bookNid, donateData);
  }


  buyBookNow(bookNid: string) {
    return this.booksGear.buyBookNow(bookNid);
  }

  checkPrice(bookId: string, userId: string) {
    return this.booksGear.checkPrice(bookId, userId);
  }

  createBookShare(bookId: string, userId: string, method: string) {
    return this.booksGear.createBookShare(bookId, userId, method);
  }


  readBook(bookNid: string, chapterNid?: string) {
    return this.booksGear.readBook(bookNid, chapterNid);
  }


}
