<div class="w-full">
  <div *ngIf="comment" class="flex justify-between leading-normal w-full pl-1 pr-4">
    <div class="flex">
      <atom-circle-image [ngClass]="comment.userId === user.userId? 'h-24 w-24' : ' h-16 w-12 min-w-[3em]'" [user]="comment.user" class="">
      </atom-circle-image>
      <div class="pl-4">
        <span class=" font-bold mb-1">{{comment.user.name}}</span>
        <span class="flex items-center mb-1 text-xs font-light ">
          {{comment.createdAt | date :'dd/MM/yyyy HH:mm'}}
        </span>
      </div>
    </div>
    <div class="pl-3">
      <div class="flex justify-between">
        <div class="flex">
          <div *ngIf="comment.userId === user.userId" class="flex">
            <button (click)="clickBtn.emit({type: 'delete-comment', commentId: comment.commentId, kind })"
              class="outline-none  font-medium mb-1 cursor-pointer pl-3">
              Xóa
            </button>
            <button (click)="clickBtn.emit({type: 'focus-input-edit-comment', comment: comment, kind })"
              class="outline-none  font-medium mb-1 cursor-pointer pl-3">
              Chỉnh sửa
            </button>
          </div>
          <button (click)="clickBtn.emit({type: 'focus-input-rep-comment', comment: comment, kind })" *ngIf="user.userId !== comment.userId"
            class="outline-none  font-medium mb-1 cursor-pointer pl-3">
            Trả lời
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <p class="md:mb-1 whitespace-pre-wrap ">{{comment.content}}</p>

    <p class="outline-none  font-medium mb-1 cursor-pointer" (click)="displayRepComment.emit({type: 'display-rep-comment', commentId: comment.commentId})"
      *ngIf="comment.childComments">
      {{comment.childComments.length ? comment.childComments.length + ' Trả lời' : ''}}
    </p>
  </div>
</div>