import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'base-btn',
  imports: [RouterModule],
  templateUrl: './base-btn.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseBtnAtom {
  @Output() submitEvent = new EventEmitter();
  @Input() chapterPosition = 1;
  @Input() path: string = '';



}
