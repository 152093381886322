import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchResultAtom } from '../search-result/search-result.atom.js';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { auditTime, switchMap, tap } from 'rxjs';
import { BooksFacade } from '@awread/core/books';
import { DialogRef } from '@ngneat/dialog';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'atom-search-navbar',
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule, SearchResultAtom],
  templateUrl: './search-navbar.atom.html',
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchNavbarAtom implements AfterViewInit {
  id = 'atom-search-navbar';
  icons = { faSearch, faTimes };
  @ViewChild('searchInput') searchElement!: ElementRef;
  placeholder = 'Tìm kiếm tên truyện, tác giả, thể loại ...';
  searchControl = new FormControl();
  dialogRef = inject(DialogRef);
  searchResult$ = this.watchingSearchTerm();
  booksFacade = inject(BooksFacade);



  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 100);
  }

  watchingSearchTerm() {
    return this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      auditTime(500),
      switchMap((term: string) => this.booksFacade.quickSearch(term)),
      tap((c) => console.log('search resut', c))
    );
  }


  toogleSearchbar() {
    this.dialogRef.close();
  }
}
