<span [ngClass]="{
  'items-center': direction === 'vertical',
  'px-1 justify-center': isSize === 2 && direction === 'horizontal',
  'px-2 justify-center': isSize === 3 && direction === 'horizontal'
}" class="flex flex-col items-center">
  <span *ngIf="loading === true;else iconTemplate" class="loading loading-spinner loading-xs"></span>
  <ng-template #iconTemplate>
    <fa-icon *ngIf="icon && (loading !== true)" [ngClass]=" {
      'text-center text-xs': isSize === 1,
      'text-center ': isSize === 2,
      'text-center text-xl': isSize === 3,
    }" [icon]="icon"></fa-icon>
  </ng-template>
  <span class="text-center" [ngClass]="{
      'text-center text-xs ml-0.5 md:ml-1': isSize === 1,
      'text-center ml-0.5 md:ml-1': isSize === 2,
      'text-center font-medium': isSize === 3,
    }">
    {{ title ?? 0 }}
  </span>


</span>