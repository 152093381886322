import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExampleDialogAtom } from '../example-dialog/example-dialog.atom.js';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, ExampleDialogAtom],
  selector: 'atom-popup-discuss-and-report-err',
  templateUrl: './popup-discuss-and-report-err.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupDiscussAndReportErrAtom implements OnInit {
  discussAndReportErrs = [
    {
      index: '1',
      title: 'Group trao đổi Facebook',
      link: 'https://www.facebook.com/groups/awread.vn/',
      icon: '/global-assets/other-website-logos/facebook.webp',
    },
    {
      index: '2',
      title: 'Kênh nhắn tin discord',
      link: 'https://discord.gg/vBrg3kTeAm',
      icon: '/global-assets/other-website-logos/icon-discord.webp',
    },
    {
      index: '3',
      title: 'Nhắn tin trực tiếp cho fanpage',
      link: 'https://m.me/105509188159417',
      icon: '/global-assets/favicon.ico',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
