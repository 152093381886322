<!-- <pre>{{notification | json}}</pre> -->
<div (click)="seeNoti.emit(notification)" [ngClass]="notification.seen ? 'shadow' : 'shadow-md shadow-accent'" class="grid grid-cols-12   cursor-pointer ">
  <div [ngClass]="isReader ? 'col-span-3 sm:col-span-2 md:col-span-1' : 'col-span-2'" class="flex items-center">
    <div class="relative overflow-hidden rounded-full w-20 h-20">
      <atom-circle-image [user]="notification.sender" class="col-span-2 sm:col-span-1"> </atom-circle-image>
    </div>
  </div>
  <div [ngClass]="isReader ? 'col-span-9 sm:col-span-10 md:col-span-11' : 'col-span-10'" class="pl-5  font-medium flex flex-col justify-center">
    <ng-container *ngTemplateOutlet="notiContent"></ng-container>
    <div class="flex items-center">
      <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      <span class="font-light ">{{notification.createdAt | date :'dd/MM/yyyy, h:mm a'}}</span>
    </div>
  </div>
</div>

<ng-template #notiContent>
  <div class="mb-2 info-noti" [ngSwitch]="notification.type">
    <span *ngIf="!notification.senderId;" class="">Admin</span>
    <span *ngSwitchCase="'comment'">
      <span class="mr-1 ">{{notification.sender.name}}</span>
      <span class="mr-1 ">đã bình luận về</span>
      <span class=" mr-1">{{notification.book.title}}</span>
    </span>
    <span *ngSwitchCase="'rep-comment'">
      <span class="mr-1 ">{{notification.sender.name}}</span>
      <span class="mr-1 ">đã trả lời bình luận của bạn về</span>
      <span class=" mr-1">{{notification.book.title}}</span>
    </span>
    <span *ngSwitchCase="'review'">
      <span class="mr-1 ">{{notification.sender.name}}</span>
      <span class="mr-1 ">đã đánh giá</span>
      <span class="">{{notification.book.title}}</span>
      <span class="font-bold" [ngClass]="notification.data?.positive || notification.data?.recommend ? 'text-primary' : 'text-red-500'">
        {{notification.data?.positive || notification.data?.recommend ? 'rất tích cực' : 'không tích cực'}}
      </span>
    </span>
    <span *ngSwitchCase="'reaction'">
      <span class="mr-1 ">{{notification.sender.name}}</span>
      <span *ngIf="notification.data.reaction === 'thích' || notification.data.reaction === 'ha ha'" class="mr-1 ">đã</span>
      <span class=" mr-1">{{notification.data.reaction}}</span>
      <span class="mr-1">đánh giá của bạn về</span>
      <span class="">{{notification.book.title}}</span>
    </span>
    <span *ngSwitchCase="'deposit:completed'" class="mr-1">
      đã chuyển cho bạn <span class="">{{ notification.data.amount | number: '1.0-0' }} Coin</span> vào tài khoản
    </span>
    <span *ngSwitchCase="'deposit:redundant'"> Nhận thấy bạn đã chuyển thừa tiền cho Admin, bạn vui lòng nhắn tin lại cho fanpage để xử lý </span>
    <span *ngSwitchCase="'deposit:lack'"> Nhận thấy bạn đã chuyển thiếu tiền cho Admin, bạn vui lòng chuyển đủ để hoàn tất thanh toán </span>
    <span *ngSwitchCase="'reward:direct'" class="mr-1">
      {{notification.data.description ?? 'Thưởng'}} <span class="">{{ notification.data.amount | number: '1.0-0' }} Coin</span>
    </span>
    <span *ngSwitchCase="'buy-book'">
      <span class="mr-1 ">{{notification.sender.name}}</span>
      <span class="mr-1 ">đã mua truyện</span>
      <span class=" mr-1">{{notification.book.title}}</span>
    </span>
    <span *ngSwitchCase="'donate'">
      <span class="mr-1 ">{{notification.sender.name}}</span>
      <span class="mr-1 ">đã donate cho truyện</span>
      <span class=" mr-1">{{notification.book.title}}</span>
      <ng-container *ngIf="notification.data.typeDonate === 0;else bread">
        <span class=" mr-1 font-bold">{{ notification.data.priceDonate | number: '1.0-0' }} Coin</span>
      </ng-container>
      <ng-template #bread>
        <span class=" mr-1 font-bold">{{notification.data.quantityDonate}} {{notification.data.name}}</span>
      </ng-template>
    </span>
    <span *ngSwitchDefault> {{ notification | json }} </span>
  </div>
</ng-template>

<ng-template #iconTemplate>
  <div class="mr-3" [ngSwitch]="notification.type">
    <fa-icon *ngSwitchCase="'rep-comment'" [icon]="icons.faComment"></fa-icon>
    <fa-icon *ngSwitchCase="'reaction'"
      [icon]="notification.data.reaction === 'thích' ? icons.faThumbsUp : notification.data.reaction === 'không thích' ? icons.faThumbsDown : icons.faFaceLaughSquint"></fa-icon>
    <fa-icon *ngSwitchCase="'comment'" [icon]="icons.faComment"></fa-icon>
    <fa-icon *ngSwitchCase="'review'" [icon]="notification.data?.recommend ||notification.data?.positive ? icons.faHeart : icons.faHeartBroken"></fa-icon>
    <fa-icon *ngSwitchCase="'deposit:completed'" [icon]="icons.faCoins"></fa-icon>
    <fa-icon *ngSwitchCase="'reward:direct'" [icon]="icons.faCoins"></fa-icon>
    <fa-icon *ngSwitchCase="'deposit:redundant'" [icon]="icons.faCoins"></fa-icon>
    <fa-icon *ngSwitchCase="'deposit:lack'" [icon]="icons.faCoins"></fa-icon>
    <fa-icon *ngSwitchDefault></fa-icon>
  </div>
</ng-template>