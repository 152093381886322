<div class="flex items-center mx-auto h-12 overflow-hidden overflow-ellipsis">
  <span [ngSwitch]="pathMode">
    <p *ngSwitchCase="'no-path'">
      <ng-container *ngTemplateOutlet="spanTemplate"></ng-container>
    </p>
    <p *ngSwitchCase="'absolute'">
      <a [href]="host + path.join('/')" target="_blank">
        <ng-container *ngTemplateOutlet="spanTemplate"></ng-container>
      </a>
    </p>
    <p *ngSwitchDefault>
      <a [routerLink]="path" [queryParams]="queryParams" [replaceUrl]="replaceUrl">
        <ng-container *ngTemplateOutlet="spanTemplate"></ng-container>
      </a>
    </p>
  </span>
  <span></span>
  <ng-template #spanTemplate>
    <span [ngClass]=" size === 's' ? 'text-xs md:' : size === 'm' ? '' : size === 'l' ? ' md:text-lg' : ' md:text-xl'"
      class="text-left font-bold break-words cursor-pointer block outline-none  transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 ">
      {{ titleBook }}
    </span>
  </ng-template>
</div>