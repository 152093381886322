import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'atom-bread-donate',
  imports: [FontAwesomeModule, CommonModule, ReactiveFormsModule],
  templateUrl: './bread-donate.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadDonateAtom implements OnInit {
  @Input() formQuantity = new FormControl();
  @Input() quatity: any;
  icons = { faChevronLeft, faChevronRight };
  @Input() item: any;
  @Input() active = false;
  @Input() price = 0;
  @Output() minusQuantity = new EventEmitter();
  @Output() plusQuantity = new EventEmitter();
  @Output() calculatePrice = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
