<div class="w-full p-2 shadow  rounded cursor-pointer">
  <!-- <pre>{{news | json}}</pre> -->
  <a [routerLink]="['/blogs', news.chapterNid]">
    <atom-rd-news-title [title]="news.chapterTitle"></atom-rd-news-title>
    <div class="">{{news.bookTitle}}</div>
    <div class="flex mt-2 text-xs text-gray-500">
      <atom-icon-text class="mr-3" [icon]="icView" [title]="news.totalView"> </atom-icon-text>
      <atom-icon-text class="mr-3" [icon]="icComment" [title]="news.totalComment"> </atom-icon-text>
    </div>
  </a>
</div>