import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'rd-list-publication-date',
  imports: [CommonModule],
  templateUrl: './rd-list-publication-date.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdListPublicationDateAtom implements OnInit {
  @Input() publicationDate = '09/04/2020';

  constructor() {}

  ngOnInit(): void {}
}
