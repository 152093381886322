import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CopyClipboardDirective } from '@awread/global/design-system';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAt, faCopy } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'rd-author-social-link-item',
  imports: [RouterModule, FontAwesomeModule, CopyClipboardDirective],
  templateUrl: './rd-author-social-link-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdAuthorSocialLinkItemAtom {
  @Input() faIcon = faAt;
  faCopy = faCopy;
  @Input() link = 'Camthuong95';



}
