import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-using-coin',
  imports: [],
  templateUrl: './using-coin.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsingCoinAtom implements OnInit {
  constructor() {}
  @Input() src = '/global-assets/images/ic_offline_pin_24px.webp';
  @Input() title = 'Hello Word';
  ngOnInit(): void {}
}
