<button class="w-full cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1">
  <ng-container *ngIf="dailyCheckin.checkinToday;else notCheckinToday">
    <div [ngClass]="dailyCheckin.currentPosition >= ui.index + 1 ? 'bg-yellow-50' : 'bg-gray-100'"
      class="flex flex-col items-center justify-center rounded-md py-1 px-3">
      <p class="md: text-xs mt-1 font-bold">+ {{ui.coin}}</p>
      <div [ngClass]="dailyCheckin.currentPosition >= ui.index + 1 ? 'bg-yellow-300' : 'bg-transparent'"
        class="md:my-3 my-1 md:w-12 md:h-12 w-8 h-8 grid place-items-center rounded-full">
        <fa-icon [icon]="dailyCheckin.currentPosition >= ui.index + 1 ? icons.faCheck : icons.faCoins" class="text-yellow-500 md:text-3xl text-xl"></fa-icon>
      </div>
    </div>
  </ng-container>

  <ng-template #notCheckinToday>
    <ng-container *ngIf="!dailyCheckin.currentPosition;else haveChain">
      <div [ngClass]="ui.index  === 0 ? 'bg-red-50 border border-red-500 shadow' : 'bg-gray-100'"
        class="flex flex-col items-center justify-center rounded-md py-1 px-3">
        <p class="md: text-xs mt-1 font-bold">+ {{ui.coin}}</p>
        <div class="md:my-3 my-1 md:w-12 md:h-12 w-8 h-8 grid place-items-center rounded-full bg-transparent">
          <fa-icon [icon]="icons.faCoins" class="text-yellow-500 md:text-3xl text-xl"></fa-icon>
        </div>
      </div>
    </ng-container>

    <ng-template #haveChain>
      <div
        [ngClass]="dailyCheckin.currentPosition + 1 > ui.index + 1 ? 'bg-yellow-50' : dailyCheckin.currentPosition + 1 < ui.index + 1 ? 'bg-gray-100' : 'bg-red-50 border border-red-500 shadow'"
        class="flex flex-col items-center justify-center rounded-md py-1 px-3">
        <p class="md: text-xs mt-1 font-bold">+ {{ui.coin}}</p>
        <div [ngClass]="dailyCheckin.currentPosition + 1 > ui.index + 1 ? 'bg-yellow-300' : 'bg-transparent'"
          class="md:my-3 my-1 md:w-12 md:h-12 w-8 h-8 grid place-items-center rounded-full">
          <fa-icon [icon]="dailyCheckin.currentPosition + 1 > ui.index + 1 ? icons.faCheck : icons.faCoins"
            class="text-yellow-500 md:text-3xl text-xl"></fa-icon>
        </div>
      </div>
    </ng-template>
  </ng-template>
  <div class="text-gray-500 w-full text-center md:text-xs text-xs py-1 px-2 font-medium mb-1">{{ui.title}}</div>
</button>

<!--
  - Co 3 TH:
    + da diem danh:
    + active ngay hom nay diem danh: {
      - Co 2 TH:
        + chua diem danh ngay hom nay
        + da diem danh ngay hom nay
    }
    + chua diem danh:
-->