import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-rd-profile-info-personal-item',
  imports: [CommonModule],
  templateUrl: './rd-profile-info-personal-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdProfileInfoPersonalItemAtom implements OnInit {
  @Input() key = 'Tên hiển thị';
  @Input() value: any;

  constructor() {}

  ngOnInit(): void {}
}
