import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'atom-icon-text',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './icon-text.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTextAtom {
  @Input() color = '';
  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
  @Input() icon = faEye;
  @Input() title!: any;
  @Input() isSize = 2;
  @Input() loading = false;



}
