import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-popup-cover-header',
  imports: [],
  templateUrl: './popup-cover-header.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCoverHeaderAtom implements OnInit {
  @Input() title = 'Chọn ảnh đại diện';
  constructor() {}

  ngOnInit(): void {}
}
