import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-btn-genre',
  imports: [CommonModule],
  templateUrl: './btn-genre.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnGenreAtom implements OnInit {
  @Input() genre!: any;
  @Output() selectGenre = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
