import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'normal-login-btn',
  imports: [CommonModule],
  templateUrl: './normal-login-btn.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NormalLoginBtn implements OnInit {
  @Output() btnClicked = new EventEmitter();
  @Input() name = 'Đăng nhập';
  @Input() active = false;
  @Input() isHidden = false;
  constructor() {}

  ngOnInit(): void {}
}
