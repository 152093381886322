import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';

import { DialogRef } from '@ngneat/dialog';
import { ExampleDialogAtom } from '../example-dialog/example-dialog.atom.js';
import { CommonModule } from '@angular/common';

interface data {
  quantity: any;
  type: any;
}
@Component({
  standalone: true,
  selector: 'atom-dialog-donate-success',
  imports: [CommonModule, ExampleDialogAtom],
  templateUrl: './dialog-donate-success.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDonateSuccessAtom implements OnInit {
  @Input() src = '/global-assets/images/Group_817.webp';
  data = this.dialogRef.data;
  constructor(public dialogRef: DialogRef<any>) {}

  ngOnInit(): void {}
}
