import { Directive, HostBinding, ElementRef, ChangeDetectorRef, EventEmitter, Output, Input, inject } from '@angular/core';
import { UniversalService } from '@awread/kernel/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, fromEvent, debounceTime, map, tap, pairwise, filter } from 'rxjs';
@UntilDestroy()
@Directive({
  selector: '[displayOnScroll]',
  standalone: true,
})
export class DisplayOnScrollDirective {
  @Input() displayOnScrollDisable = false;
  private hasView = false;
  // @HostBinding('class') classes = 'transition-all duration-300 ease-in-out';
  @Output() displayOnScroll = new EventEmitter();
  element = inject(ElementRef);
  cd = inject(ChangeDetectorRef);
  universalService = inject(UniversalService);
  ngOnInit() {
    if (this.universalService.isBrowser() && this.displayOnScrollDisable === false) {
      fromEvent(document, 'scroll', { passive: false })
        .pipe(
          // every time you move the mouse we get and event
          // lets add a minimum debounce to make the console's log lets crowded
          debounceTime(15),

          // ignore the event's value, lets use the current vertical position instead
          map((e) => window.scrollY),
          // tap((res) => console.log('height:', res)),

          // pairwise() converts the response into an array of 2 elements: [ previous, current ]
          pairwise(),
          // tap((res) => console.log('pairwise:', res)),

          // compute the vertical relative offset
          // res[1] = current
          // res[0] = previous
          map((res) => res[1] - res[0]),
          // tap((res) => console.log('offset:', res)),

          // drop all the offset below a custom treshold
          filter((res) => Math.abs(res) > 15),

          // Mape the offset from number to boolean:
          // - if the scroll is toward the top (negative offset) then its "true" so we'll show the sticky header
          // - but if we are at the top of the screen just return "false
          // map((res) => window.pageYOffset !== 0 && res < 0),
          // tap((res) => console.log(`window.pageYOffset ${window.pageYOffset} | delta ${res}`)),
          map((res) => (window.scrollY < 3 ? true : res < 0)), // <==== AWREAD VERSION: update a little, alway show on top
          // tap((res) => console.log('direction:', res)),
          // distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
          distinctUntilChanged(),
          // tap((res) => console.log('direction only when it change:', res)),
          untilDestroyed(this)
        )
        .subscribe((condition) => {
          const hiddenClass = 'max-h-0 overflow-hidden py-0'.split(' ');
          if (!condition && !this.hasView) {
            console.log('hidden');
            // this.viewContainer.createEmbeddedView(this.templateRef).detectChanges();
            this.hasView = true;
            (this.element.nativeElement as HTMLElement).classList.add(...hiddenClass);
            //   console.log('remove h0', this.classes);
            //   console.log('make it hidden', this.classes);
          } else if (condition && this.hasView) {
            console.log('show now!');
            this.displayOnScroll.next('show');
            // this.viewContainer.clear();
            (this.element.nativeElement as HTMLElement).classList.remove(...hiddenClass);
            this.hasView = false;
          }
          this.cd.markForCheck();
        });
    }
  }
}
