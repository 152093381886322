import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'atom-text-link',
  imports: [RouterModule, CommonModule],
  templateUrl: './text-link.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextLinkAtom implements OnInit {
  @Input() text = 'textLink';
  @Input() haveNoti = false;
  @Input() linkData: string[] | undefined;
  @Input() class = 'text-primary';
  @Input() blank = false;
  constructor() {}

  ngOnInit(): void {}
}
