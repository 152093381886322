import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { CurrentUser, CurrentUserQuery } from '../states/current-user/index.js';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class CurrentUserApi {
  constructor(
    private apollo: Apollo,
    private currentUserQuery: CurrentUserQuery,
  ) { }
  getBookUserReading(bookId: string) {
    if (this.currentUserQuery.getUserId()) {
      return this.apollo
        .query<any>({
          query: gql`
            query bookUserReading($bookId: UUID!, $userId: UUID) {
              vReadings(condition: { bookId: $bookId, userId: $userId }) {
                nodes {
                  bookId
                  # bookNid
                  chapterCount
                  latestVisit
                  readedPercent
                  totalReadTime
                  userId
                }
              }
            }
          `,
          variables: {
            userId: this.currentUserQuery.getUserId(),
            bookId,
          },
        })
        .pipe(
          map((res) => ({
            ...res?.['data']?.['vReadings']?.['nodes'][0],
          })),
        );
    } else {
      return of([]);
    }
  }

  getCurrentUser() {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation thisUser {
            thisUser(input: {}) {
              results {
                role
                users {
                  userNid
                  userId
                  username
                  email
                  phone
                  name
                  updatedAt
                  avatar
                }
                personals {
                  firstname
                  middlename
                  lastname
                  dob
                  gender
                  bio
                  websiteAddress
                  facebookAddress
                  genreIds
                  haveNoti
                  haveDialogNoti
                  serviceObj
                  contactObj
                }
              }
            }
          }
        `,
      })
      .pipe(
        map((res) => ({
          ...res.data?.['thisUser']?.['results']?.[0]?.['users'],
          ...res.data?.['thisUser']?.['results']?.[0]?.['personals'],
          role: res.data?.['thisUser']?.['results']?.[0]?.['role'],
        })),
      );
  }

  createPersonal(personal: CurrentUser) {
    const userId = this.currentUserQuery.getUserId();
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation createPersonal(
          $userId: UUID!
          $firstname: String
          $middlename: String
          $lastname: String
          $dob: String
          $bio: String
          $websiteAddress: String
          $facebookAddress: String
          $zaloAddress: String
          $gender: Int
          $genreIds: [Int]
        ) {
          createPersonal(
            input: {
              personal: {
                userId: $userId
                firstname: $firstname
                middlename: $middlename
                lastname: $lastname
                dob: $dob
                bio: $bio
                websiteAddress: $websiteAddress
                facebookAddress: $facebookAddress
                zaloAddress: $zaloAddress
                gender: $gender
                genreIds: $genreIds
              }
            }
          ) {
            personal {
              firstname
              middlename
              lastname
              dob
              bio
              websiteAddress
              facebookAddress
              zaloAddress
              gender
              genreIds
            }
          }
        }
      `,
      variables: {
        ...personal,
        userId,
      },
    });
  }

  updatePersonal(personal: CurrentUser) {
    const userId = this.currentUserQuery.getUserId();
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updatePersonal(
          $userId: UUID!
          $firstname: String
          $middlename: String
          $lastname: String
          $dob: String
          $bio: String
          $websiteAddress: String
          $facebookAddress: String
          $zaloAddress: String
          $gender: Int
          $genreIds: [Int]
          $haveNoti: Boolean
          $haveDialogNoti: Boolean
          $contactObj: JSON
          $serviceObj: JSON
        ) {
          updatePersonal(
            input: {
              userId: $userId
              patch: {
                userId: $userId
                firstname: $firstname
                middlename: $middlename
                lastname: $lastname
                dob: $dob
                bio: $bio
                websiteAddress: $websiteAddress
                facebookAddress: $facebookAddress
                zaloAddress: $zaloAddress
                gender: $gender
                genreIds: $genreIds
                haveNoti: $haveNoti
                haveDialogNoti: $haveDialogNoti
                contactObj: $contactObj
                serviceObj: $serviceObj
              }
            }
          ) {
            personal {
              firstname
              middlename
              lastname
              dob
              bio
              websiteAddress
              facebookAddress
              zaloAddress
              gender
              genreIds
              haveNoti
              haveDialogNoti
              serviceObj
              contactObj
            }
          }
        }
      `,
      variables: {
        ...personal,
        userId,
      },
    });
  }

  updateUser(user: CurrentUser) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updateUser($userId: UUID!, $username: String, $email: String, $phone: String, $name: String, $avatar: Datetime, $updatedAt: Datetime) {
          updateUser(
            input: { userId: $userId, patch: { username: $username, email: $email, phone: $phone, name: $name, avatar: $avatar, updatedAt: $updatedAt } }
          ) {
            user {
              username
              email
              phone
              name
              avatar
              updatedAt
            }
          }
        }
      `,
      variables: {
        ...user,
        userId: this.currentUserQuery.getUserId(),
      },
    });
  }

  linkSocial(credential: { provider: string; providerId: string }) {
    const socialConnect = {
      ...this.currentUserQuery.getSocialConnect(),
      [credential?.provider]: credential?.providerId,
    };
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updateSocial($socialConnect: JSON) {
          updateSocial(input: { socialConnect: $socialConnect }) {
            uuid
          }
        }
      `,
      variables: {
        socialConnect,
      },
    });
  }

  refreshToken() {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation refreshToken($clientMutationId: String) {
            refreshToken(input: { clientMutationId: $clientMutationId }) {
              results {
                accessToken
              }
            }
          }
        `,
        variables: {
          clientMutationId: this.currentUserQuery.getUserId(),
        },
      })
      .pipe(map((result: any) => result?.['data']?.['refreshToken']?.['results']?.[0]?.['accessToken']));
  }

  updateRole(role: string) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation upgradeRole {
            upgradeRole(input: {}) {
              string
            }
          }
        `,
      })
      .pipe(map((result: any) => result?.['data']?.['upgradeRole']?.['string']));
  }

  updateHaveNoti(haveNoti?: any) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updateHaveNoti($userId: UUID!, $haveNoti: Boolean) {
          updatePersonal(input: { userId: $userId, patch: { haveNoti: $haveNoti } }) {
            personal {
              haveNoti
            }
          }
        }
      `,
      variables: {
        haveNoti,
        userId: this.currentUserQuery.getUserId(),
      },
    });
  }

  updateHaveDialogNoti(haveDialogNoti?: any) {
    return this.apollo.mutate<any>({
      mutation: gql`
        mutation updateHaveDialogNoti($userId: UUID!, $haveDialogNoti: Boolean) {
          updatePersonal(input: { userId: $userId, patch: { haveDialogNoti: $haveDialogNoti } }) {
            personal {
              haveDialogNoti
            }
          }
        }
      `,
      variables: {
        haveDialogNoti,
        userId: this.currentUserQuery.getUserId(),
      },
    });
  }
}
