import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appBlockCopyPaste]',
  standalone: true,
})
export class BlockCopyPasteDirective {
  @Input() appBlockCopyPaste = true;
  constructor() {}

  // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
  //   e.preventDefault();
  // }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (this.appBlockCopyPaste) {
      e.preventDefault();
    }
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (this.appBlockCopyPaste) {
      e.preventDefault();
    }
  }
}
