import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CircleImageAtom } from '../circle-image/circle-image.atom.js';
import { CloseOnOutsideDirective } from '@awread/global/packages';

@Component({
  standalone: true,
  selector: 'atom-search-result',
  imports: [CommonModule, ReactiveFormsModule, CircleImageAtom, RouterModule],
  templateUrl: './search-result.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultAtom {
  @Output() focusInput = new EventEmitter();
  @Output() toggleDisplay = new EventEmitter();
  @Input() inputControl = new FormControl();
  @Input() searchResult = {
    authors: [] as any[],
    books: [] as any[],
  };
  tracker = (book: any) => book.bookId || book.bookNid;




}
