<div class="bg w-full rounded py-2 md:py-4">
  <div class="grid grid-cols-12 px-4 md:px-6 lg:px-8 xl:px-10 h-16 md:h-36">
    <div class="col-span-5 flex flex-col items-center justify-center w-full transform -translate-y-12 md:-translate-y-14 xl:-translate-y-28">
      <atom-img-m class="transform scale-110" [book]="book"></atom-img-m>
    </div>

    <div class="col-span-7 relative ml-4 md:ml-6">
      <div class="flex items-start mx-auto overflow-hidden overflow-ellipsis">
        <a [routerLink]="['/b', book.bookNid]"
          class=" md:text-lg lg:text-xl text-left font-bold mb-1 break-words cursor-pointer block outline-none text-white ">
          {{ book.title }}
        </a>
      </div>

      <div [routerLink]="['/a', book.username]" class="text-xs sm: cursor-pointer outline-none truncate text-white flex py-0.5 ">
        {{book.name}}
      </div>


      <!-- <p class="pt-0.5 leading-5 break-words text-left text-white text-xs sm: mb-1  h-10 lg:h-20">
        {{ book.description === null ? 'Hiện tại truyện chưa có phần giới thiệu' : book.description}}
      </p> -->
    </div>
  </div>
</div>