import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBookmark, faComment, faEye } from '@fortawesome/free-regular-svg-icons';
import { IconTextAtom } from '../icon-text/icon-text.atom.js';
import { RdNewsTitleAtom } from '../rd-news-title/rd-news-title.atom.js';

@Component({
  standalone: true,
  selector: 'atom-rd-news-awread-item',
  imports: [RouterModule, CommonModule, FontAwesomeModule, RdNewsTitleAtom, IconTextAtom],
  templateUrl: './rd-news-awread-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdNewsAwreadItemAtom implements OnInit {
  @Input() news: any;
  icView = faEye;
  icBookMark = faBookmark;
  icComment = faComment;
  constructor() {}

  ngOnInit(): void {}
}
