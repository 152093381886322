import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
@Component({
  standalone: true,
  selector: 'atom-review-action',
  imports: [FontAwesomeModule],
  templateUrl: './review-action.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      :host .bg-green {
        background-color: rgba(90, 189, 140, 0.4078);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewActionAtom implements OnInit {
  @Output() submitEvent = new EventEmitter();
  icons = {
    faHeart,
    faHeartRegular,
  };
  @Input() recommend = true;
  @Input() title = 'Rất tích cực';
  constructor() {}

  ngOnInit(): void {}
}
