import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CurrentUserGear } from '../gears/index.js';
import { CurrentUser, CurrentUserQuery, CurrentUserStore } from '../states/current-user/index.js';
import { Location } from '@angular/common';
import { SnackbarService } from '@awread/global/packages';

@Injectable({ providedIn: 'root' })
export class CurrentUserFacade {
  private currentUserGear = inject(CurrentUserGear);
  public currentUserQuery = inject(CurrentUserQuery);
  public currentUserStore = inject(CurrentUserStore);
  private snackbarService = inject(SnackbarService);
  location = inject(Location);
  currentUser$ = this.currentUserQuery.select();
  logout$ = new Subject<boolean>();

  onUserIdChanges() {
    if (!localStorage.getItem('accessToken') && localStorage.getItem('userId')) {
      console.warn('cannot see token?! reloading...', localStorage.getItem('accessToken'), localStorage.getItem('userId'));
      // localStorage.clear();
      // window.location.reload();
      console.warn('accessToken is not working...');
      this.snackbarService.showWarning('onUserIdChanges: vui lòng đăng nhập lại');
      localStorage.clear();
      // debugger;
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    return this.currentUser$.pipe(
      map((user) => {
        if (user.userNid && user.userId) {
          return user.userId;
        } else {
          return null;
        }
      }),
      distinctUntilChanged()
    );
  }

  getBookUserReading(bookId: string) {
    return this.currentUserGear.getBookUserReading(bookId);
  }

  connectThisAccountToSocial(user: any) {
    return this.currentUserGear.connectThisAccountToSocial(user);
  }

  agreeBecomeWriter() {
    return this.currentUserGear.agreeBecomeWriter();
  }

  fetchUserData() {
    return this.currentUserGear.fetchUserData();
  }

  updateUser(user: any) {
    return this.currentUserGear.updateUser(user);
  }

  updatePersonal(user: any) {
    return this.currentUserGear.updatePersonal(user);
  }

  getUserId() {
    if (this.currentUserQuery.getUserId()) return this.currentUserQuery.getUserId();
    else return undefined;
  }
  getUserNid() {
    if (this.currentUserQuery.getUserNid()) return this.currentUserQuery.getUserNid();
    else return undefined;
  }

  getUserNidRefLink(link: string) {
    if (link.includes('friendNid')) {
      return link;
    } else {
      return this.getUserNid() ? link + `?friendNid=${this.getUserNid()}` : link;
    }
  }

  thisUserNotAuthor(userId: string) {
    return !!this.getUserId() && !!userId && this.getUserId() !== userId;
  }

  getRegisterCredential() {
    return this.currentUserQuery.getValue().registerCredential;
  }

  setSocialCredential(value?: any) {
    this.currentUserStore.update({ registerCredential: value });
  }

  reset() {
    this.currentUserStore.reset();
    this.logout$.next(true);
  }

  getRole() {
    if (this.currentUserQuery.getRole()) return this.currentUserQuery.getRole();
    else return undefined;
  }

  updateHaveNoti(user: any) {
    return this.currentUserGear.updateHaveNoti(user);
  }

  updateHaveDialogNoti(haveDialogNoti?: any) {
    return this.currentUserGear.updateHaveDialogNoti(haveDialogNoti);
  }
}
