import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollSpy]',
  standalone: true,
})
export class ScrollSpyDirective {
  @Input() public spiedTags: any[] = [];
  @Output() public contentChange = new EventEmitter<string>();
  private currentContent = '';

  constructor(private _el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    let currentContent = '';
    const children = this._el.nativeElement.children;
    const scrollTop = event.target.scrollTop;
    const parentOffset = event.target.offsetTop;
    // for (let i = 0; i < children.length; i++) {
    //   const element = children[i];
    //   if (this.spiedTags.some((spiedTag) => spiedTag === element.tagName)) {
    //     if (element.offsetTop - parentOffset - 8 <= scrollTop) {
    //       currentContent = element.id;
    //     }
    //   }
    // }

    for (const element of children) {
      if (this.spiedTags.some((spiedTag) => spiedTag === element.tagName)) {
        if (element.offsetTop - parentOffset - 8 <= scrollTop) {
          currentContent = element.id;
        }
      }
    }

    if (currentContent !== this.currentContent) {
      this.currentContent = currentContent;
      this.contentChange.emit(this.currentContent);
    }
  }
}
