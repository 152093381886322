<div [formGroup]="profileForm" class="mb-6 flex items-center">
  <label class="text-gray-400 w-2/5">{{item.title}}</label>
  <div class="w-3/5">
    <select
      [formControlName]="item.formControlName"
      [ngClass]="item.class"
      class="border rounded-full border-gray-200 bg-white outline-none focus:outline-none focus:ring-1 focus:ring-gray-300 focus:border-gray-300"
    >
      <option *ngFor="let option of item.options" [ngValue]="option.value" class="py-1">{{option.text}}</option>
    </select>
  </div>
</div>
