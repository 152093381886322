import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-title',
  imports: [CommonModule],
  templateUrl: './title.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleAtom {
  @Input() title = 'Title';
  @Input() color = '';
  @Input() isUppercase: true | false = true;
  @Input() fontSize: 1 | 2 | 3 = 2;



}
