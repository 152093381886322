<div class="relative">
  <scroll-snap [scrollSnapConfig]="scrollSnapConfig" [arrayData]="data.sliders">
    <div #slideItem *ngFor="let item of data.sliders" [id]="item.id" class="slide-item p-6 md:p-9">
      <ng-container *ngIf="item.target; then thenTemplate; else elseTemplate"></ng-container>
      <ng-template #thenTemplate>
        <a [href]="item.href" [target]="'_blank'">
          <img [ngSrc]="item.src" [priority]="priority" width="1200" height="630" style="aspect-ratio: 40/21" class="w-img max-w-none" [alt]="item.alt" />
        </a>
      </ng-template>
      <ng-template #elseTemplate>
        <a [routerLink]="item.href" [target]="'_self'">
          <img [ngSrc]="item.src" [priority]="priority" width="1200" height="630" style="aspect-ratio: 40/21" class="w-img max-w-none" [alt]="item.alt" />
        </a>
      </ng-template>
    </div>
  </scroll-snap>
</div>