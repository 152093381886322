import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CircleImageAtom } from '../circle-image/circle-image.atom.js';

@Component({
  standalone: true,
  selector: 'atom-review-input',
  imports: [CommonModule, ReactiveFormsModule, CircleImageAtom],
  templateUrl: './review-input.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
     
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewInputAtom {
  @ViewChild('input', { read: ElementRef }) input!: ElementRef<HTMLInputElement>;
  @Input() mode: 'parent' | 'child' = 'parent';
  @Input() placeholder = 'Để lại bình luận trên 10 ký tự và có ý nghĩa để nhận thưởng Coin từ Awread nhé (Tối đa 1 lần nhận thưởng trong ngày)';
  @Input() isComment = true;
  @Input() inputControl: FormControl = new FormControl('');
  @Output() actionComment = new EventEmitter();
  @Input() type = '';
  @Input() user: any;
  @Input() comment: any;
  constructor(private cd: ChangeDetectorRef) { }



  focusInput() {
    this.input.nativeElement.focus();
  }
}
