<div [ngClass]="active ? 'bg-green-100 rounded-md md:rounded-2xl' : '' "
  class="font-medium flex justify-center items-center flex-col py-2 px-2 rounded-md cursor-pointer">
  <div class="md:h-16">
    <img src="{{item.img}}" class="w-10/12 md:w-full" />
  </div>
  <div class="font-bold md:text-2xl pb-1 md:pb-1 mt-2">{{item.name}}</div>

  <div class="">{{item.coin | number: '1.0-0'}} Coin</div>
</div>
<div *ngIf="active">
  <div class="flex justify-center mt-3">
    <fa-icon *ngIf="formQuantity.value && formQuantity.value > 1" (click)="minusQuantity.emit()"
      class="w-6 rounded-l-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 shadow cursor-pointer"
      [icon]="icons.faChevronLeft"></fa-icon>
    <input (keyup)="calculatePrice.emit()" type="number" [formControl]="formQuantity" class=" text-center w-9 shadow-xl focus:outline-none  font-bold" />
    <fa-icon (click)="plusQuantity.emit()"
      class="w-6 rounded-r-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 shadow cursor-pointer"
      [icon]="icons.faChevronRight"></fa-icon>
  </div>
  <div class="mt-2 flex justify-center text-xs sm: lg:text-md">( {{price | number: '1.0-0'}} Coin )</div>
</div>