import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BooksStore, BooksState } from './books.store.js';
// import { BooksStore, BooksState, BooksUIState } from './books.store';

@Injectable({ providedIn: 'root' })
export class BooksQuery extends QueryEntity<BooksState> {
  books$ = this.selectAll();
  isLoading$ = this.selectLoading();
  filtersChange$ = this.select((state) => state.ui.filters);

  // ui!: EntityUIQuery<BooksUIState>;
  constructor(protected override store: BooksStore) {
    super(store);
    // this.createUIQuery();
  }

  getCurrentFilter() {
    return this.getValue().ui.filters;
  }
}
