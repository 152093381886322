import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
@Component({
  standalone: true,
  selector: 'atom-rating-simple',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './rating-simple.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingSimpleAtom implements OnInit {
  icons = {
    faHeart,
    faHeartRegular,
  };
  @Input() recommend = true;
  @Input() size = '';
  constructor() {}

  ngOnInit(): void {}
}
