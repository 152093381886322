import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoverSizePipe } from '@awread/core/books';

@Component({
  standalone: true,
  selector: 'atom-img-m',
  imports: [CommonModule, RouterModule, CoverSizePipe, NgOptimizedImage],
  templateUrl: './img-m.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgMAtom {
  // ONLY FOR BOOK NOT FOR AVATAR, AVATAR using <atom-circle-image></atom-circle-image>
  @Input() size: 's' | 'm' | 'l' | 'xl' | 'xxl' | 'origin' = (globalThis as any)['isMobile'] ? 'l' : 'xxl';
  @Input() book: any;
  @Input() priority = false;
  @Input() rounded = 'rounded-xl';
  @Input() showRibbon = true;
  @Input() bigImage = false;
  @Input() queryParams = {};
  @Input() startPath = '/b';
  @Input() replaceUrl = false;



}
