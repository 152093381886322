import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-terms-and-service-item',
  imports: [],
  templateUrl: './terms-and-service-item.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndServiceItemAtom implements OnInit {
  @Input() title = '';
  @Input() content = '';

  constructor() {}

  ngOnInit(): void {}
}
