import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';

import { DialogRef } from '@ngneat/dialog';

export interface data {
  message: string;
}
@Component({
  standalone: true,
  selector: 'app-deposit-completed',
  imports: [],
  templateUrl: './deposit-completed.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      :host .shadoww {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
      }
    `,
  ],
})
export class DepositCompletedComponent implements OnInit {
  data = this.dialogRef.data;
  constructor(public dialogRef: DialogRef<any>, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    console.log('deposit-completed component data', this.data);
    // mat dialog not open full and need workaround to trigger
    setTimeout(() => {
      this.cd.detectChanges();
    }, 100);
  }

  accept() {
    this.dialogRef.close(true);
  }
}
