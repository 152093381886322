import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
@Component({
  standalone: true,
  selector: 'atom-price',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './price.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceAtom implements OnChanges {
  @Input() price = 5000;
  @Input() percent = 50;
  @Input() currentPrice = 2000;
  @Input() endDate = '';
  @Input() isDiscount: any;
  @Input() currentDate = new Date(Date.now()).getTime();
  faCoins = faCoins;
  @Input() className = 'flex  text-[#D8280E] flex-wrap items-center'

  ngOnChanges(): void {
    if (this.currentDate > new Date(this.endDate).getTime()) {
      this.isDiscount = false;
    } else {
      this.isDiscount = true;
    }
    this.currentPrice = (+this.price - +this.price * (this.percent / 100));
  }
}
