import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'atom-rd-dropdown-menu-mb',
  imports: [FontAwesomeModule, CommonModule, RouterModule],
  templateUrl: './rd-dropdown-menu-mb.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdDropdownMenuMbAtom implements OnInit {
  @Input() faIcon = faCaretRight;
  @Input() faIcon2 = faCaretDown;
  @Input() isHiddenMenu = true;
  @Output() action = new EventEmitter();
  @Output() closeMenu = new EventEmitter();
  @Input() link: any;

  constructor() {}

  ngOnInit(): void {}

  displayMenu() {
    this.isHiddenMenu = !this.isHiddenMenu;
  }
}
