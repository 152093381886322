<atom-example-dialog class="h-full w-full">
  <span dialog-title>Thảo luận và báo lỗi</span>
  <section class=" relative h-full py-4">
    <div class="px-2 sm:px-8 py-2 sm:py-4 flex flex-col justify-center">
      <div *ngFor="let itemDiscussAndReportErr of discussAndReportErrs" class="py-4">
        <a target="_blank" [href]="itemDiscussAndReportErr.link" class="cursor-pointer outline-none flex items-center">
          <div class="transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100">
            <img class="h-10 sm:h-12 rounded-full border" [src]="itemDiscussAndReportErr.icon" />
          </div>
          <div class="truncate">
            <div class="ml-2  sm:text-lg cursor-pointer">{{itemDiscussAndReportErr.title}}</div>
            <div class="text-blue-500 ml-2  sm: cursor-pointer ">{{itemDiscussAndReportErr.link}}</div>
          </div>
        </a>
      </div>
    </div>
  </section>

</atom-example-dialog>