<div *ngIf="link" class="border-b py-3 relative">
  <div class="font-bold  pr-4 cursor-pointer" (click)="displayMenu()">
    {{ link.title }}
    <fa-icon (click)="null" [icon]="isHiddenMenu ? faIcon : faIcon2" class=" cursor-pointer pointer-events-none"></fa-icon>
  </div>
  <div [hidden]="isHiddenMenu" class="mt-2">
    <ng-container *ngFor="let item of link.dropdown">
      <ng-container *ngIf="item.group; else linkTemplate">
        <div (click)="action.emit({group: item.group, event: item })" [ngClass]="item.selected ? 'bg-accent' : ''"
          class="outline-none cursor-pointer  block w-full py-2 pl-6 hover:bg-primary hover:text-white">
          {{item.title}}
        </div>
      </ng-container>
      <ng-template #linkTemplate>
        <a (click)="closeMenu.emit({ group: 'link', type: 'closeMenu' })" [routerLink]="item.href" [queryParams]="item.isQueryParams ? item.queryParams : null"
          class="outline-none cursor-pointer  block w-full py-2 pl-6 hover:bg-primary hover:text-white">{{item.title}}</a>
      </ng-template>
    </ng-container>
  </div>
</div>