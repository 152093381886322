import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'atom-author',
  imports: [CommonModule, RouterModule],
  templateUrl: './author.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorAtom implements OnInit {
  @Input() authors: any;
  @Input() title = '';
  @Input() color = 'text-primary';

  constructor() {}

  ngOnInit(): void {}
}
