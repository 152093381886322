import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  standalone: true,
  selector: 'atom-round-btn',
  imports: [],
  templateUrl: './round-btn.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundBtnAtom implements OnInit {
  @Input() title = 'Đọc chương đầu';
  @Input() color = 'bg-primary text-white';
  @Input() href = '/';
  @Output() submitEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
