import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'atom-icon-btn',
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './icon-btn.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconBtnAtom implements OnInit {
  @Input() icon = faThumbsUp;
  @Input() active = false;
  @Input() reaction = 0;
  @Input() value = '999';
  @Input() count = {
    countReactionLike: 0,
    countReactionDislike: 0,
    countReactionFunny: 0,
  };
  @Output() submitEvent = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
