<div class="w-screen max-w-screen-xl flex items-start align-center h-96 relative">
  <input #searchInput class="w-full text-xl md:text-2xl lg:text-3xl border-primary shadow-accent shadow-sm bg-base-200 px-4 rounded-full focus:outline-none"
    [formControl]="searchControl" [placeholder]="'Tìm kiếm tên truyện, tác giả, thể loại ...'" [id]="id" />
  <div class="absolute sm:top-1.5 top-0.5 right-3">
    <ng-container *ngIf="searchControl.value; else elseTemplate">
      <fa-icon class=" text-primary cursor-pointer" [icon]="icons.faTimes" (click)="searchControl.reset();  searchInput.focus()"></fa-icon>
    </ng-container>
    <ng-template #elseTemplate>
      <fa-icon class=" text-primary cursor-pointer" [icon]="icons.faTimes" (click)="toogleSearchbar()"></fa-icon>
    </ng-template>
  </div>
  <atom-search-result class="z-50 left-0 top-12 absolute w-full " [inputControl]="searchControl" [searchResult]="searchResult$ | async"
    (focusInput)="searchInput.focus();" (toggleDisplay)="toogleSearchbar()">
  </atom-search-result>
</div>