import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  standalone: true,
  selector: 'auth-bg',
  imports: [],
  templateUrl: './auth-bg.atom.html',
  styleUrls: ['./auth-bg.atom.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthBgAtom {}
