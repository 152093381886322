import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BtnInputFileAtom } from '../btn-input-file/btn-input-file.atom.js';

@Component({
  standalone: true,
  selector: 'atom-popup-cover-image-choose',
  imports: [BtnInputFileAtom],
  templateUrl: './popup-cover-image-choose.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCoverImageChooseAtom implements OnInit {
  @Input() controlName = new FormControl('');
  @Output() eventChooseImage = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
