import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'atom-rd-news-title',
  imports: [CommonModule, RouterModule],
  templateUrl: './rd-news-title.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RdNewsTitleAtom {
  @Input() newsId = '';
  @Input() title = 'Title';
  @Input() class = '';




}
